import React, { useEffect } from 'react'
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom'
import { IconButton, Paper, Tooltip } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {
  getInforme,
  updateResume,
  getMessages,
  createMessage,
  updateVisibilidad,
  sendEmail
} from '../Api/vistaInforme.api'
import { toast } from 'react-toastify'
import { NodoContext } from '../Context/nodoContext'
import { LayoutContext } from '../Context/layoutContext'
import { FavoritosTabs } from '../Favoritos/favoritosTabs'
import Confirm from '../general-components/confirm'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import LockIcon from '@mui/icons-material/Lock'
import { ContentCopy } from '@mui/icons-material'
import EmailIcon from '@mui/icons-material/Email';
import useMediaQuery from '@mui/material/useMediaQuery';
import { descomprimirPayload, downloadInformeS3 } from '../Utils/utilities';
import { getFilePresignedUrl } from '../Api/files.api';

export function InformesDetalle() {
  const { informeId } = useParams()
  const [informe, setInforme] = React.useState(null)
  const [nombreInforme, setNombreInforme] = React.useState('')
  const [resumenInforme, setResumenInforme] = React.useState('')
  const [informePublico, setInformePublico] = React.useState(false)
  const [permissionDenied, setPermissionDenied] = React.useState(false)
  const [emailInformeConf, setEmailInformeConf] = React.useState(false);
  const { setLoading, setLoadingMessage } = React.useContext(NodoContext)
  const { fireInformeReload } = React.useContext(LayoutContext)
  const [messages, setMessages] = React.useState([])
  const [openPublicConf, setOpenPublicConf] = React.useState(false)
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate()

  useEffect(() => {
    updateInforme()
  }, [informeId, fireInformeReload])

  function updateInforme() {
    if (!informeId) return
    setLoadingMessage('Cargando informe...')
    setLoading(true)
    getInforme(informeId).then(async (response) => {
      setLoading(false)
      if (response.status === 200) {

        let dataInforme = {}
        if (response.body.informe.data) {
          dataInforme = descomprimirPayload(response.body.informe.data)
        } else if (response.body.informe.pathS3) {
          // obtener de S3
          const resp = await getFilePresignedUrl(response.body.informe.pathS3);
          if (resp.status !== 200) {
            toast.error(`Error al obtener el informe: ${resp.body}`);
            return;
          }
          const { data, error } = await downloadInformeS3(resp.body.url);
          if (error) {
            toast.error(`Error al obtener el informe: ${data}`);
            return;
          }
          dataInforme = data;
        }
        setNombreInforme(response.body.informe.nombre)
        setResumenInforme(response.body.informe.resumen)
        setInformePublico(response.body.informe.publico)
        createSummaryHash(dataInforme, response.body.summary)
      } else if (response.status === 401 || response.status === 404) {
        setPermissionDenied(true)
      } else {
        toast.error('Ocurrió un error al obtener el informe')
      }
    })

    getMessages(informeId).then((response) => {
      setMessages(response.body)
    })
  }

  const updateResume = async (content) => {
    setResumenInforme(content)
  }

  const setDescripcionTab = (tabId, descripcion) => {
    const informeUpdated = informe
    informeUpdated.tabs = informeUpdated.tabs.map((item) => {
      if (item.id === tabId) item.descripcion = descripcion
      return item
    })

    setInforme(informeUpdated)
  }

  const grabarMensaje = async (data) => {
    await createMessage(data)
    getMessages(informeId).then((response) => {
      setMessages(response.body)
    })
  }

  function handleVisibilityChange() {
    updateVisibilidad({ id: informeId, publico: !informePublico }).then(
      (response) => {
        if (response.status === 200) {
          toast.success('Informe actualizado')
          setInformePublico(!informePublico)
        } else {
          toast.error('Ocurrió un error al actualizar el informe')
        }
      }
    )
  }

  function createSummaryHash(informe, summary) {
    // const informe = JSON.parse(informeString)
    const hash = {}
    summary.archivoSummary.forEach((item) => {
      hash[item.tabId] = {
        comentarios: 0,
        archivos: item.total
      }
    })
    summary.commentSummary.forEach((item) => {
      hash[item.tabId] = {
        comentarios: item.total,
        archivos: hash[item.tabId] ? hash[item.tabId].archivos : 0
      }
    })
    informe.tabs.forEach((tab) => {
      if (!hash[tab.id]) {
        tab.comentarios = 0
        tab.archivos = 0
      } else {
        tab.comentarios = hash[tab.id].comentarios
        tab.archivos = hash[tab.id].archivos
      }
    })
    if (informe.filtros) informe.filtros = JSON.parse(informe.filtros || '[]')
    console.log({ informe })
    setInforme(informe)
  }

  const actualizaResumen = async (data) => {
    try {
      updateResume(data)
    } catch (error) {
      console.error(error)
    }
  }

  const copiarLink = () => {
    const link = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/informes/${informeId}`
    navigator.clipboard.writeText(link)
    toast.success('Link copiado')
  }

  function sendEmailInforme() {
    if (informeId !== null) {
      setLoading(true);
      setLoadingMessage("Enviando email...");
      sendEmail(informeId).then((response) => {
        setLoading(false);
        if (response.status === 201) {
          toast.success("Email enviado correctamente");
          setEmailInformeConf(false);
        } else {
          toast.error("Error al enviar el email");
        }
      });
    }
  }
  
  let inf
  if (!resumenInforme) {
    inf = { id: informeId, resumen: '', mensajes: messages }
  } else {
    inf = { id: informeId, resumen: resumenInforme, mensajes: messages }
  }

  return (
    <>
      <div>
        {permissionDenied && (
          <div
            style={{
              marginTop: '85px',
              maxHeight: 'calc(100vh - 105px)',
              overflowY: 'auto'
            }}
          >
            <h1>Acceso denegado</h1>
            <h3>
              El informe al que intenta acceder no existe o no es público. Por
              favor, contacte con el administrador.
            </h3>
          </div>
        )}
        {!permissionDenied && (
          <>
            <div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: matches ? 0 : '0 20px'
                }}
              >
                <Tooltip title="Regresar">
                  <IconButton onClick={() => navigate(-1)}>
                    <ArrowBackIcon />
                  </IconButton>
                </Tooltip>
                <div style={{ display: 'flex' }}>
                  <Tooltip
                    title={informePublico ? 'Hacer privado' : 'Hacer público'}
                  >
                    <IconButton onClick={handleVisibilityChange}>
                      {informePublico ? <LockOpenIcon /> : <LockIcon />}
                    </IconButton>
                  </Tooltip>
                  {informePublico && (
                    <Paper sx={{ padding: '10px' }}>
                      <b style={matches ? { fontSize: '8pt' } : {}}>Link:</b>{' '}
                      <a
                        style={matches ? { fontSize: '8pt' } : {}}
                        href={`${window.location.protocol}//${window.location.hostname}:${window.location.port}/informes/${informeId}`}
                      >{`${window.location.protocol}//${window.location.hostname}:${window.location.port}/informes/${informeId}`}</a>
                      <Tooltip title="Copiar link">
                        <IconButton onClick={copiarLink}>
                          <ContentCopy />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Enviar correo">
                        <IconButton onClick={() => setEmailInformeConf(true)}>
                          <EmailIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                    </Paper>
                  )}
                </div>
              </div>
              <h1>{nombreInforme}</h1>
              <h3>{informe?.descripcion}</h3>
              {informe?.tabs && (
                <FavoritosTabs
                  tabs={informe.tabs}
                  leyendas={informe.leyendas}
                  esInforme={true}
                  informe={inf}
                  vista={{ datasetS3: informe.datasetS3, filtros: informe.filtros || [] }}
                  recargarInforme={actualizaResumen}
                  setDescripcionTab={setDescripcionTab}
                  grabarMensaje={grabarMensaje}
                  resultadosEvaluacion={informe.resultadosEvaluacion}
                  esTableroPersonal={informe.tableroPersona}
                  fechaInicioConfirmada={new Date(informe.fechaInicial)}
                  fechaFinConfirmada={new Date(informe.fechaFinal)}
                />
              )}
            </div>
            <Confirm
              open={openPublicConf}
              title={'Eliminar'}
              text={'¿Confirma que desea eliminar este informe?'}
              onConfirm={handleVisibilityChange}
              handleCloseProp={() => setOpenPublicConf(false)}
            />
            <Confirm
              open={emailInformeConf}
              title={"Enviar informe por correo"}
              text={"¿Confirma que desea enviar este informe por correo?"}
              onConfirm={sendEmailInforme}
              handleCloseProp={() => setEmailInformeConf(false)}
            />
          </>
        )}
      </div>
    </>
  )
}

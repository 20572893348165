import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify'
import { Box, Grid, Card, CardActions, CardContent, TextField, Button, IconButton } from "@mui/material";
import { NodoContext } from '../../Context/nodoContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { PeriodoContext } from '../../Context/periodoContext';
import { MessageBar } from '../../general-components/messageBar';
import { getAboutUs, saveAboutUs } from '../../Api/estrategia.api';
import RemoveIcon from '@mui/icons-material/Cancel';

const AboutUs = () => {

    const location = useLocation()
    const navigate = useNavigate();

    const { setLoading, setLoadingMessage } = React.useContext(NodoContext)
    const { getUnicoPeriodoSeleccionado } = React.useContext(PeriodoContext);

    const [periodoId, setPeriodoId] = useState(null)

    const [about_us, setAboutUs] = useState(null)
    const [purpose, setPurpose] = useState(null)
    const [values, setValues] = useState([])

    const [newAboutUs, setNewAboutUs] = useState(null)
    const [newPurpose, setNewPurpose] = useState(null)
    const [newValues, setNewValues] = useState([])

    const [editingAboutUs, setEditingAboutUs] = useState(false)
    const [editingPurpose, setEditingPurpose] = useState(false)
    const [editingValues, setEditingValues] = useState(false)

    useEffect(() => {
        const _periodoId = getUnicoPeriodoSeleccionado()?.id
        if (_periodoId !== periodoId) {
            setPeriodoId(_periodoId)
        }
    }, [getUnicoPeriodoSeleccionado()])

    useEffect(() => {
        if (periodoId) {
            setLoading(true)
            setLoadingMessage('Cargando información...')
            getAboutUs(periodoId)
                .then((response) => {
                    setAboutUs(response.body.about_us)
                    setPurpose(response.body.purpose)
                    setValues(response.body.values || [])
                    setNewAboutUs(response.body.about_us)
                    setNewPurpose(response.body.purpose)
                    setNewValues(response.body.values || [])
                    setLoading(false)
                })
                .catch((error) => {
                    toast.error('Error al cargar la información')
                    setLoading(false)
                })
        }
    }, [periodoId])

    const SaveAboutUs = (value, field) => {
        setLoading(true)
        setLoadingMessage('Guardando información...')
        saveAboutUs({
            [field]: value,
        }, periodoId)
            .then(() => {
                toast.success('Información guardada correctamente')
                setLoading(false)
                switch (field) {
                    case 'about_us':
                        setAboutUs(value)
                        setNewAboutUs(value)
                        setEditingAboutUs(false)
                        break;
                    case 'purpose':
                        setPurpose(value)
                        setNewPurpose(value)
                        setEditingPurpose(false)
                        break;
                    case 'values':
                        setValues(value)
                        setNewValues(value)
                        setEditingValues(false)
                        break;
                    default:
                        break;
                }
            })
            .catch(() => {
                toast.error('Error al guardar la información')
                setLoading(false)
            })
    }

    if (getUnicoPeriodoSeleccionado() === null)
        return (
            <MessageBar
                display={true}
                type="error"
                message="Debe de seleccionar un único periodo para visualizar los resultados"
                title="Error"
            />
        )

    return (
        <Grid container sx={{ padding: '2em', justifyContent: 'center' }} spacing={3}>
            {/* Quienes somos */}
            <Grid item xs={12} md={6} sx={{ paddingY: '1em' }}>
                <Card
                    onDoubleClick={() => {
                        setEditingAboutUs(true)
                    }}
                >
                    <CardContent>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <h1>¿Quiénes somos?</h1>
                        </Box>
                    </CardContent>
                    <CardContent>
                        <Box display="flex" justifyContent="left" alignItems="left">
                            {!editingAboutUs ? (
                                <p style={{ textAlign: 'left' }}>
                                    {about_us}
                                </p>
                            ) : (
                                <TextField
                                    fullWidth
                                    multiline
                                    autoFocus
                                    rows={4}
                                    value={newAboutUs}
                                    onChange={(e) => setNewAboutUs(e.target.value)}
                                />
                            )}
                        </Box>
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'right' }}>
                        {editingAboutUs && (
                            <>
                                <Button
                                    onClick={() => {
                                        setEditingAboutUs(false)
                                        setNewAboutUs(about_us)
                                    }}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    variant='contained'
                                    onClick={() => {
                                        SaveAboutUs(newAboutUs, 'about_us')
                                    }}
                                >
                                    Guardar
                                </Button>
                            </>
                        )}
                    </CardActions>
                </Card>
            </Grid>
            {/* Proposito */}
            <Grid item xs={12} md={6} sx={{ paddingY: '1em' }}>
                <Card
                    onDoubleClick={() => {
                        setEditingPurpose(true)
                    }}
                >
                    <CardContent>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <h1>Propósito</h1>
                        </Box>
                    </CardContent>
                    <CardContent>
                        <Box display="flex" justifyContent="left" alignItems="left">
                            {!editingPurpose ? (
                                <p style={{ textAlign: 'left' }}>
                                    {purpose}
                                </p>
                            ) : (
                                <TextField
                                    fullWidth
                                    multiline
                                    autoFocus
                                    rows={4}
                                    value={newPurpose}
                                    onChange={(e) => setNewPurpose(e.target.value)}
                                />
                            )}
                        </Box>
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'right' }}>
                        {editingPurpose && (
                            <>
                                <Button
                                    onClick={() => {
                                        setEditingPurpose(false)
                                        setNewPurpose(purpose)
                                    }}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    variant='contained'
                                    onClick={() => {
                                        SaveAboutUs(newPurpose, 'purpose')
                                    }}
                                >
                                    Guardar
                                </Button>
                            </>
                        )}
                    </CardActions>
                </Card>
            </Grid>
            {/* Valores */}
            <Grid item xs={12} md={6} sx={{ paddingY: '1em' }}>
                <Card
                    onDoubleClick={() => {
                        setEditingValues(true)
                    }}
                >
                    <CardContent>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <h1>Valores</h1>
                        </Box>
                    </CardContent>
                    <CardContent>
                        <Box display="flex" justifyContent="left" alignItems="left">
                            {!editingValues ? (
                                <p style={{ textAlign: 'left' }}>
                                    {values.map((value, index) => (
                                        <li key={index}>{value}</li>
                                    ))}
                                </p>
                            ) : (
                                <Grid container>
                                    {newValues.map((value, index) => (
                                        <Grid item xs={12} key={index} sx={{ paddingY: 1 }}>
                                            <TextField
                                                fullWidth
                                                autoFocus
                                                value={value}
                                                onChange={(e) => {
                                                    const newValuesCopy = [...newValues]
                                                    newValuesCopy[index] = e.target.value
                                                    setNewValues(newValuesCopy)
                                                }}
                                                onKeyUp={(e) => {
                                                    if (e.key === 'Enter') {
                                                        const newValuesCopy = [...newValues]
                                                        newValuesCopy.push('')
                                                        setNewValues(newValuesCopy)
                                                    }
                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <IconButton
                                                            onClick={() => {
                                                                const newValuesCopy = [...newValues]
                                                                newValuesCopy.splice(index, 1)
                                                                setNewValues(newValuesCopy)
                                                            }}
                                                        >
                                                            <RemoveIcon />
                                                        </IconButton>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </Box>
                    </CardContent>
                    {/* Añadir nuevo valor a la lista */}
                    {editingValues && (
                        <CardActions sx={{ justifyContent: 'right' }}>
                            <Button
                                onClick={() => {
                                    const newValuesCopy = [...newValues]
                                    newValuesCopy.push('')
                                    setNewValues(newValuesCopy)
                                }}
                            >
                                Añadir valor
                            </Button>
                        </CardActions>
                    )}
                    <CardActions sx={{ justifyContent: 'right' }}>
                        {editingValues && (
                            <>
                                <Button
                                    onClick={() => {
                                        setEditingValues(false)
                                        setNewValues(values)
                                    }}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    variant='contained'
                                    onClick={() => {
                                        SaveAboutUs(newValues.filter(newValue => newValue != ""), 'values')
                                    }}
                                >
                                    Guardar
                                </Button>
                            </>
                        )}
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    )
}

export default AboutUs;
import React from "react";
import {
  Grid,
  List,
  Chip,
  Paper,
  Button,
  useTheme,
  MenuItem,
  TextField,
  Typography,
  ListItemIcon,
  ListItemText,
  ToggleButton,
  useMediaQuery,
  ListItemButton,
  ToggleButtonGroup,
  toggleButtonGroupClasses,
  IconButton,
  Tooltip,
  Badge,
} from "@mui/material";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { styled } from '@mui/material/styles';
import TagIcon from '@mui/icons-material/Tag';
import SellIcon from '@mui/icons-material/Sell';
import { GRAPH_TYPES } from "../../Chart/toolbarChart";
import { getMetadataDataset } from "../../Api/vistaDatasets";
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { GRAFICAS_BASICAS, OPERACIONES_AGG } from "../../Utils/graphConstants";
import { validarConfig } from "./validaciones";
import { FilterBuilder } from "devextreme-react";

const inputStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '0.5rem',
  width: '100%',
  padding: '1rem',
  border: '1px solid #2196f3',
  borderRadius: '5px',
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
  {
    marginLeft: -1,
    borderLeft: '1px solid transparent',
  },
}));

export default function GraficaDSFrom({
  isOpen, addFn, loading, closeFn, datasetKey, config,
  setLoading, setLoadingMessage
}) {

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const [metadata, setMetadata] = React.useState([]);
  const [dataset, setDataset] = React.useState([]);
  const [titulo, setTitulo] = React.useState('');
  const [tipoGrafica, setTipoGrafica] = React.useState('line');
  const [camposEjeX, setCamposEjeX] = React.useState([]);
  const [camposEjeY, setCamposEjeY] = React.useState([]);
  const [camposGroupby, setCamposGroupby] = React.useState([]);
  const [operacionesAgg, setOperacionesAgg] = React.useState([]);
  const [sortedBy, setSortedBy] = React.useState({})
  const [filterBy, setFilterBy] = React.useState([]);

  React.useEffect(() => {
    obtenerMetadata();
  }, [datasetKey]);

  React.useEffect(() => {
    if (isOpen) {
      setTitulo('');
      setTipoGrafica('line');
      setCamposEjeX([]);
      setCamposEjeY([]);
      setCamposGroupby([]);
      setOperacionesAgg([]);
      setSortedBy({});
      setFilterBy([]);
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (config) {
      setTitulo(config.titulo);
      setTipoGrafica(config.tipoGrafica);
      setCamposEjeX(config.camposEjeX);
      setCamposEjeY(config.camposEjeY);
      setCamposGroupby(config.camposGroupby);
      setOperacionesAgg(config.operacionesAgg);
      setSortedBy(config.sortedBy || {});
      setFilterBy(config.filterBy || []);
    }
  }, [config]);

  const obtenerMetadata = async () => {
    try {
      setLoading(true)
      setLoadingMessage('Obteniendo metadata...')
      const response = await getMetadataDataset(datasetKey?.key);
      setMetadata(response.body);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const changeChartType = (type) => {
    setTipoGrafica(type);
    if (!GRAFICAS_BASICAS.includes(type) || !GRAFICAS_BASICAS.includes(tipoGrafica)) {
      setCamposEjeX([]);
      setCamposEjeY([]);
      setCamposGroupby([]);
      setOperacionesAgg([]);
    }
  }

  // Funcion para obtener dataset

  // Funciones para ordenar campos
  const getCamposSort = () => {
    const campos = camposEjeX.concat(camposEjeY).concat(camposGroupby);
    return campos;
  }

  const getIconOrden = () => {
    if (!sortedBy.columna) return null;
    if (sortedBy.ascending) return <ArrowUpwardIcon sx={{ fontSize: '15px' }} />;
    return <ArrowDownwardIcon sx={{ fontSize: '15px' }} />;
  }

  // Funciones para filtros
  const onValueFilterChanged = React.useCallback((e) => {
    console.log('onValueFilterChanged', e.value);
    setFilterBy(e.value);
  }, [setFilterBy]);

  const getFields = () => {
    const fields = [];
    metadata.forEach((item) => {
      fields.push({
        dataField: item.columna,
        caption: item.columna,
        dataType: item.tipo === 'object' ? 'string' : 'number',
      });
    });
    return fields;
  }

  // Funciones Drag and Drop
  const onDragStart = (event, item) => {
    event.dataTransfer.setData("draggedItem", JSON.stringify(item));
  };

  const onDragOver = (event) => event.preventDefault();

  const onDrop = (event, destinationListSetter) => {
    const draggedItem = JSON.parse(event.dataTransfer.getData("draggedItem"));

    console.log('event destino', event.destino);

    // Si el item ya existe en la lista B, evitar duplicados
    if (camposEjeX.some(item => item.columna === draggedItem.columna)) return;

    const data = {
      titulo,
      tipoGrafica,
      camposEjeX,
      camposEjeY,
      camposGroupby,
      operacionesAgg,
    }
    data[event.destino] = [...data[event.destino], draggedItem]
    console.log('DragAndDrop data ', data);
    const errores = validarConfig(data);
    console.log('DragAndDrop errores ', errores);
    if (errores[event.destino]) {
      toast.error(errores[event.destino]);
      return;
    }

    // Actualizar la lista destino
    destinationListSetter((prevItemsB) => [...prevItemsB, draggedItem]);

    if (event.destino === 'camposEjeY') setOperacionesAgg([...operacionesAgg, null]);
  };

  const deleteItemEjeY = (index) => {
    setCamposEjeY(camposEjeY.filter((_, i) => i !== index))
    setOperacionesAgg(operacionesAgg.filter((_, i) => i !== index))
  }

  const validateFields = (data) => {
    // TODO: Validar campos requeridos y logica de las combinaciones
    const errores = validarConfig(data);
    if (!titulo) {
      toast.error('Ingrese un título para la gráfica');
      return false;
    } else if (!tipoGrafica) {
      toast.error('Seleccione un tipo de gráfica');
      return false;
    } else if (!camposEjeX.length) {
      toast.error('Seleccione al menos un campo para el eje X');
      return false;
    } else if (!camposEjeY.length) {
      toast.error('Seleccione al menos un campo para el eje Y');
      return false;
    } else if (Object.keys(errores).length) {
      Object.keys(errores).forEach((key) => {
        toast.error(errores[key]);
      });
      return false;
    }
    return true;
  }

  const handleSave = () => {
    const data = {
      titulo,
      tipoGrafica,
      camposEjeX,
      camposEjeY,
      camposGroupby,
      operacionesAgg,
      sortedBy,
      filterBy,
    }

    if (config.resumen) data.resumen = config.resumen;

    // TODO: Validar campos requeridos y logica de las combinaciones
    if (validateFields(data)) {
      addFn(data);
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: matches ? 'row' : 'column', width: '100%' }}>
      {/* Metadata Card */}
      <div style={{ display: 'flex', flex: 0.4, maxHeight: '80vh', overflowX: 'hidden', overflowY: 'auto' }}>
        <Grid item container component={Paper} sx={{ paddingX: 1 }} boxShadow={"none"}>
          <List component="nav" style={{ width: '100%' }} >
            {metadata.map((item, index) => (
              <ListItemButton
                key={index}
                selected={true}
                draggable
                onDragStart={(event) => onDragStart(event, item)}
                sx={{ marginBottom: 1, cursor: "grab" }}
              >
                <ListItemIcon title={item.tipo}>
                  {item.tipo === 'object' ? <SellIcon /> : <TagIcon />}
                </ListItemIcon>
                <ListItemText primary={item.columna} sx={{ wordBreak: 'break-word' }} />
              </ListItemButton>
            ))}
          </List>
        </Grid>
      </div>
      {/* Configuracion Card */}
      <div style={{ display: 'flex', flex: 0.6 }}>
        <Grid
          item
          container
          component={Paper}
          alignContent='flex-start'
          sx={{ paddingX: 2, maxHeight: '80vh', overflowX: 'hidden', overflowY: 'auto' }}
        >
          <Typography variant='h6' sx={{ marginTop: 2 }}>Configuración</Typography>

          {/* Campo Nombre de la gráfica */}
          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <TextField
              id="titulo"
              label="Título de la gráfica"
              variant="outlined"
              fullWidth
              value={titulo}
              onChange={(event) => setTitulo(event.target.value)}
            />
          </Grid>

          {/* Campo Tipo de gráfica */}
          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>Tipo de gráfica</Typography>
            <StyledToggleButtonGroup
              value={tipoGrafica}
              exclusive
              onChange={(event, value) => changeChartType(value)}
              color="standard"
              fullWidth
            >
              {GRAPH_TYPES.map((action) => (
                <ToggleButton value={action.type} title={action.name}>
                  {action.icon}
                </ToggleButton>
              ))}
            </StyledToggleButtonGroup>
          </Grid>

          {/* Campos Eje X */}
          <Grid
            item
            xs={12}
            onDragOver={onDragOver}
            onDrop={(event) => onDrop({ ...event, destino: 'camposEjeX' }, setCamposEjeX)}
            sx={{ marginTop: 2 }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>Campos Eje X</Typography>
            <div style={inputStyle}>
              {camposEjeX.map((item, index) => (
                <Chip
                  key={index}
                  label={item.columna}
                  onDelete={() => setCamposEjeX(camposEjeX.filter((_, i) => i !== index))}
                />
              ))}
            </div>
          </Grid>

          {/* Campos Eje Y */}
          <Grid
            item
            xs={12}
            onDragOver={onDragOver}
            onDrop={(event) => onDrop({ ...event, destino: 'camposEjeY' }, setCamposEjeY)}
            sx={{ marginTop: 2 }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>Campos Eje Y</Typography>
            <div style={inputStyle}>
              {camposEjeY.map((item, index) => (
                <Chip
                  key={index}
                  label={item.columna}
                  onDelete={() => deleteItemEjeY(index)}
                />
              ))}
            </div>
          </Grid>

          {/* Campos Group by */}
          <Grid
            item
            xs={12}
            onDragOver={onDragOver}
            onDrop={(event) => onDrop({ ...event, destino: 'camposGroupby' }, setCamposGroupby)}
            sx={{ marginTop: 2 }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>Agrupado por:</Typography>
            <div style={inputStyle}>
              {camposGroupby.map((item, index) => (
                <Chip
                  key={index}
                  label={item.columna}
                  onDelete={() => setCamposGroupby(camposGroupby.filter((_, i) => i !== index))}
                />
              ))}
            </div>
          </Grid>

          {/* Campos Agg */}
          <Grid
            item
            xs={12}
            sx={{ marginTop: 2 }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>Operaciones de Agregación</Typography>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              {camposEjeY.map((item, index) => (
                <div key={index} style={{ display: 'flex', width: '100%', marginTop: '1rem' }}>
                  <TextField
                    id={`campo-${index}`}
                    value={item.columna}
                    InputProps={{ readOnly: true }}
                    variant="standard"
                    sx={{ minWidth: '150px' }}
                  />
                  <TextField
                    id={`agg-${index}`}
                    select
                    value={operacionesAgg[index] || null}
                    onChange={(event) => {
                      const newOperacionesAgg = [...operacionesAgg];
                      newOperacionesAgg[index] = event.target.value;
                      setOperacionesAgg(newOperacionesAgg);
                    }}
                    placeholder="Operación"
                    variant="standard"
                    sx={{ marginLeft: '1rem', minWidth: '150px' }}
                  >
                    {OPERACIONES_AGG.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              ))}
            </div>
          </Grid>

          {/* Campos Sort */}
          <Grid
            item
            xs={12}
            sx={{ marginTop: 2 }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>Ordenar por</Typography>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              <div style={{ display: 'flex', width: '100%', marginTop: '1rem' }}>
                <TextField
                  id={`sorted`}
                  select
                  value={sortedBy.columna || null}
                  onChange={(event) => {
                    setSortedBy({ columna: event.target.value, ascending: true });
                  }}
                  placeholder="Campo a ordenar"
                  variant="standard"
                  sx={{ minWidth: '250px' }}
                >
                  {getCamposSort().map((option) => (
                    <MenuItem key={option.columna} value={option.columna}>
                      {option.columna}
                    </MenuItem>
                  ))}
                </TextField>

                <Tooltip
                  title={sortedBy.columna ? sortedBy.ascending ? 'Ascendente' : 'Descendente' : 'Sin ordenar'}
                  placement="right"
                >
                  <IconButton
                    onClick={() => {
                      console.log('sortedBy', sortedBy);
                      if (!sortedBy.columna) return;
                      setSortedBy({ ...sortedBy, ascending: !sortedBy.ascending });
                    }}
                    color={sortedBy.columna ? 'primary' : 'default'}
                    onDoubleClick={() => setSortedBy({})}
                    sx={{ marginLeft: '1rem' }}
                  >
                    <Badge
                      color="default"
                      badgeContent={getIconOrden()}
                    >
                      <SortByAlphaIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </Grid>

          {/* Campos Filtros */}
          <Grid
            item
            direction='row'
            xs={12}
            sx={{ marginTop: 2 }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>Filtros de diseñador</Typography>
            {metadata.length && (
              <div style={{ display: 'flex', flexDirection: 'column', flex: 1, padding: '1rem' }}>
                <FilterBuilder fields={getFields()} value={filterBy} onValueChanged={onValueFilterChanged} />
                {/* <Button text="Apply Filter" type="default" onClick={buttonClick} /> */}
                <div className="dx-clearfix"></div>
              </div>
            )}
          </Grid>
          
          <div style={{ marginTop: '3rem', marginBottom: '1rem' }}>
            <Button
              variant="contained"
              color='inherit'
              style={{ marginRight: '1rem' }}
              onClick={closeFn}
            >
              Cancelar
            </Button>
            <LoadingButton
              loading={loading}
              disabled={loading}
              onClick={handleSave}
              color='primary'
              variant='contained'
            >
              <span>Guardar</span>
            </LoadingButton>
          </div>
        </Grid>
      </div>

    </div>
  )
}

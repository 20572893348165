import { HOST } from "../Utils/config";
import { deleteRequest, getRequest, postRequest, putRequest } from "./requests";

async function getNodos() {
  return await getRequest(`${HOST}/api/v1/nodos`);
}

async function getArbolNodosConIndicadores() {
  return await getRequest(`${HOST}/api/v1/nodos/indicadores`);
}

async function getNodo(idNodo) {
  return await getRequest(`${HOST}/api/v1/nodos/${idNodo}`);
}

async function copiarNodo(config) {
  return await postRequest(`${HOST}/api/v1/nodos/copiar`, config);
}

async function postNodo(nodo) {
  return await postRequest(`${HOST}/api/v1/nodos`, nodo);
}

async function postNodoIA(nodo) {
  return await postRequest(`${HOST}/api/v1/nodos/ia`, nodo);
}

async function saveNodoIA(nodo) {
  return await postRequest(`${HOST}/api/v1/nodos/saveIa`, nodo);
}


async function cambiarOrdenNodo(movementParams) {  
  return await putRequest(`${HOST}/api/v1/nodos/orden`, movementParams);
}

async function putNodo(nodo) {
  return await putRequest(`${HOST}/api/v1/nodos`, nodo);
}

async function deleteNodo(nodoId) {
  return await deleteRequest(`${HOST}/api/v1/nodos/${nodoId}`);
}


export {
  getNodos,
  getArbolNodosConIndicadores,
  postNodo,
  postNodoIA,
  saveNodoIA,
  putNodo,
  getNodo,
  deleteNodo,
  cambiarOrdenNodo,
  copiarNodo
};

import React from "react";
import "./style.module.css";
import CkEditor from "../general-components/ckeditor";
import OkCancelButtons from "../general-components/ok-cancel-buttons";
import { updateDescripcionTab } from "../Api/vistaInforme.api";
import { Typography } from "@mui/material";

function DescripcionTab(props) {
  const [editableMode, setEditableMode] = React.useState(false);
  const [dataState, setDataState] = React.useState("");
  const [dataBk, setDataBk] = React.useState("");
  const [tabObj, setTabobj] = React.useState({});
  //const { nodoActual, reloadNodoActual } = React.useContext(NodoContext);

  React.useEffect(() => {    
    setDataState(props.tab.descripcion);
    setDataBk(props.tab.descripcion);
    setTabobj(props.tab);
  }, [props.tab]);
  
  React.useEffect(() => {    
    guardarComentario()
  }, [props.tabIndex, props.tabIndexPre]);

  const guardarComentario = async() => {
    if (props.tabIndex !== props.tabIndexPre) {
      await save();
      props.handleTabChange(props.tabIndexPre)
    }
  }

  const setEdit = (value) => {
    setEditableMode(value);
    setDataBk(dataState);
  };
  const cancelFn = () => {
    setEditableMode(false);
    setDataState(dataBk);
  };

  const handleChangeData = async (value) => {
    setDataState(value);
  };

  const save = async () => {
    if (dataBk !== dataState) {
      //code to save      
      const json = {
        id: props.informeId,
        tabId: tabObj.id,
        descripcion: dataState,
      };
      const despBK = tabObj.descripcion ?? '';
      setDataBk(dataState);
      setEdit(false);
      const res = await updateDescripcionTab(json);
      props.setDescripcionTab(tabObj.id, dataState);
      if (res.status !== 200) {
        setDataState(despBK);
      }
    }
    setEdit(false);
  };

  return (
    <>
      {!editableMode ? (
        !!dataState ?
        <div
          dangerouslySetInnerHTML={{
            __html: dataState,
          }}
          style={{
            textAlign: "justify",
            padding: "25px 25px",
            width: "100%",
          }}
          className="editable-object"
          onClick={() => {
            if (props.autenticado) {
              setEdit(true);
            }
          }}
        />
        :
        <div
          style={{
            textAlign: "justify",
            padding: "25px 25px",
            width: "100%",
          }}
          className="editable-object empty"
          onClick={() => {
            if (props.autenticado) {
              setEdit(true);
            }
          }}
        >
          <Typography style={{ margin: '1rem 0' }} color='GrayText'>
            {!!props.autenticado ? 'Haga click para insertar descripción' : 'Sin descripción' }
          </Typography>
        </div>
      ) : (
        <>
          <CkEditor
            value={dataState}
            setValue={handleChangeData}
            titulo={""}
          />
          <OkCancelButtons okFn={save} cancelFn={cancelFn} />
        </>
      )}
    </>
  );
}

export default DescripcionTab;

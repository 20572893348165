import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';

import NormalIcon from '../Utils/normalIcon';
import HistogramIcon from '../Utils/histogramIcon';
import CloseIcon from '@mui/icons-material/Close';
import BarChartIcon from '@mui/icons-material/BarChart'
import TimelineIcon from '@mui/icons-material/Timeline'
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot'
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart'

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: 'absolute',
  '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  '>.MuiButtonBase-root': {
    width: '40px',
    height: '40px',
  },
}));

export const GRAPH_TYPES = [
  { icon: <TimelineIcon color="error" />, name: 'Gráfica de lineas', type: 'line' },
  { icon: <BarChartIcon color="warning" />, name: 'Gráfica de barras', type: 'bar'},
  { icon: <ScatterPlotIcon color="secondary" />, name: 'Gráfica de dispersión', type: 'scatter'},
  { icon: <CandlestickChartIcon color="primary" />, name: 'Gráfica de caja', type: 'candlestick'},
  { icon: <NormalIcon />, name: 'Gráfica de distribución', type: 'distribucion'},
  { icon: <HistogramIcon />, name: 'Gráfica de histograma', type: 'histograma'},
];

const ToolbarChart = React.memo(({ tipoVista, setTipoVista }) => {
  
  console.log('Render ToolbarChart ', tipoVista);
  
  const renderIcon = () => {
    const action = GRAPH_TYPES.find(action => action.type === tipoVista);
    return action?.icon || GRAPH_TYPES[0].icon;
  }

  return (
    <Box sx={{ position: 'relative'}}>
      <StyledSpeedDial
        ariaLabel="SpeedDial playground example"
        icon={<SpeedDialIcon icon={renderIcon()} openIcon={<CloseIcon/>} />}
        direction={'right'}
        FabProps={{ color: 'default' }}
      >
        {GRAPH_TYPES.map((action) => (
          <SpeedDialAction
            sx={{ marginLeft: '3px', marginRight: '3px' }}
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => setTipoVista(action.type)}
          />
        ))}
      </StyledSpeedDial>
    </Box>
  )
});

export default ToolbarChart;

import React from 'react'
import moment from 'moment'
import { useState } from 'react'
import { TURNOS } from '../Utils/enums'
import { DEFAULT_DELAY } from '../Utils/config'

import { Autocomplete, Button, Grid, IconButton, Paper, TextField, Tooltip } from '@mui/material'
import { ModalOpcionesVistaGlobal } from '../general-components/modalOpcionesVistaGlobal'
import {
  putTipoVisualizacion,
  putVisbilidadEtiquetas,
  putVistaTab
} from '../Api/vistaIndicadorTab.api'
import { toast } from 'react-toastify'
import { sendDataToAnalyze } from '../Api/analysis.api'
import { putVistaIndicadorDetalle } from '../Api/vistaIndicadorDetalle.api'
import DraggableDialog from '../general-components/confirm'

import AbcIcon from '@mui/icons-material/Abc'
import NormalIcon from '../Utils/normalIcon';
import ClearIcon from '@mui/icons-material/Clear';
import GridOnIcon from '@mui/icons-material/GridOn'
import RestoreIcon from '@mui/icons-material/Restore';
import TimelineIcon from '@mui/icons-material/Timeline'
import BarChartIcon from '@mui/icons-material/BarChart'
import VisibilityIcon from '@mui/icons-material/Visibility'
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot'
import InsertChartIcon from '@mui/icons-material/InsertChart'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import PivotTableChartIcon from '@mui/icons-material/PivotTableChart'
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart'
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import FilterFormModal from './Datasets/filterFormModal'
import { putFiltros } from '../Api/vistaIndicador.api'
import { json } from 'react-router-dom'
import { getDataFilters } from '../Api/vistaDatasets'

let globalChartType = null
let globalShowValues = null
let globalShowAnalysis = null

const actions = [
  { icon: <TimelineIcon color="error" />, name: 'Gráfica de lineas', type: 'line' },
  { icon: <BarChartIcon color="warning" />, name: 'Gráfica de barras', type: 'bar' },
  { icon: <ScatterPlotIcon color="secondary" />, name: 'Gráfica de dispersión', type: 'scatter' },
  { icon: <CandlestickChartIcon color="primary" />, name: 'Gráfica de caja', type: 'candlestick' },
  { icon: <NormalIcon />, name: 'Gráfica de distribución', type: 'distribucion' },
];

export function FavoritosOpciones({
  fechaInicioRef,
  fechaFinRef,
  fechaInicioConfirmada,
  fechaFinConfirmada,
  saveViewChanges,
  keyToReload,
  vista,
  nombreInforme,
  setNombreInforme,
  actualizarEsBorrador,
  createJsonData,
  tabs,
  setTabs,
  tabIndex,
  tableroId,
  resetearFechasPeriodos,
  diasZafra,
  diaZafraSelected,
  setDiaZafraSelected,
  turnoSelected,
  setTurnoSelected,
  datasetS3,
  filtros,
  updateFiltros
}) {
  const [openConfiguracionGlobal, setOpenConfiguracionGlobal] = useState(false)
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [confirmationModal2, setConfirmationModal2] = useState(false);
  const [openFilterFormModal, setOpenFilterFormModal] = useState(false);
  const [visualFilters, setVisualFilters] = useState([]);
  const [opcionesFiltros, setOpcionesFiltros] = useState([]);

  React.useEffect(() => {
    console.log('useEffect filtros ', filtros)
    setVisualFilters(filtros)
  }, [filtros])

  React.useEffect(() => {
    if (datasetS3 && visualFilters?.length) {
      getDataFiltros()
    }
  }, [datasetS3, visualFilters])

  const getDataFiltros = async () => {
    try {
      const response = await getDataFilters({ s3_key: datasetS3, visualFilters })
      if (response.status === 200 || response.status === 201) setOpcionesFiltros(response.body);
    } catch (error) {
      console.error(error)
    }
  }

  const tab = vista.tableroPersona ? tabs[tabIndex - 1] : tabs[tabIndex]

  function aplicarCambiosGlobales(aplicarAPestanaActual) {
    if (globalShowValues !== null) {
      aplicarCambiosDeEtiqueta(aplicarAPestanaActual)
    } else if (globalShowAnalysis !== null) {
      aplicarCambiosAnalisis(aplicarAPestanaActual)
    } else if (globalChartType !== null) {
      aplicarCambiosDeTipoGrafica(aplicarAPestanaActual)
    }
    setOpenConfiguracionGlobal(false)
  }

  function aplicarCambiosDeEtiqueta(aplicarAPestanaActual) {
    if (aplicarAPestanaActual) {
      const actualTab = tabs.filter((tab, index) => index === tabIndex)[0]
      actualTab.indicadores.forEach((indicador) => {
        // indicador.prevId = indicador.id
        // indicador.id = indicador.id * 2
        indicador.mostrarEtiquetas = globalShowValues.show
      })
      if (vista.tipoUsuario !== 'R') {
        saveVisibilidadEtiquetas(globalShowValues.show, tableroId, actualTab.id)
      }
    } else {
      if (vista.tipoUsuario !== 'R') {
        saveVisibilidadEtiquetas(globalShowValues.show, tableroId, null)
      }
      tabs.forEach((tab) => {
        tab.indicadores.forEach((indicador) => {
          // indicador.prevId = indicador.id
          // indicador.id = indicador.id * 2
          indicador.mostrarEtiquetas = globalShowValues.show
        })
      })
    }
    const tabsCopy = [...tabs]
    globalShowValues = null
    setTabs([])
    setTimeout(() => {
      setTabs(tabsCopy)
    }, 100);
  }

  async function aplicarCambiosAnalisis(aplicarAPestanaActual) {
    let localTabs = []
    let indicadorhash = {}
    if (aplicarAPestanaActual) {
      const actualTab = tabs.filter((tab, index) => index === tabIndex)[0]
      actualTab.indicadores.forEach((indicador) => {
        indicadorhash[indicador.id] = indicador
        indicador.prevId = indicador.id
        indicador.id = indicador.id * 2
        indicador.mostrarAnalisis = globalShowAnalysis.show
      })
      localTabs.push(actualTab)
    } else {
      tabs.forEach((tab) => {
        tab.indicadores.forEach((indicador) => {
          indicadorhash[indicador.id] = indicador
          indicador.prevId = indicador.id
          indicador.id = indicador.id * 2
          indicador.mostrarAnalisis = globalShowAnalysis.show
        })
      })
      localTabs = tabs
    }
    const dataToAnalyze = generateDataToAnalyze(localTabs)
    const results = await sendDataToAnalyze(dataToAnalyze)
    if (results.status !== 200) {
      return toast.error('Error al analizar los datos')
    }
    Object.entries(results.body).forEach(([key, value]) => {
      indicadorhash[key].analysisData = value
    })
    const tabsCopy = [...tabs]
    globalShowAnalysis = null
    setTabs([])
    setTimeout(() => {
      setTabs(tabsCopy)
    }, 100);
  }

  function aplicarCambiosDeTipoGrafica(aplicarAPestanaActual) {
    if (aplicarAPestanaActual) {
      const actualTab = tabs.filter((tab, index) => index === tabIndex)[0]
      actualTab.indicadores.forEach((indicador) => {
        // indicador.prevId = indicador.id
        // indicador.id = indicador.id * 2
        indicador.tipoGrafica =
          globalChartType.viewType === 'mixed'
            ? indicador.tipoGrafica
            : globalChartType.chartType
        indicador.tipoVista = globalChartType.viewType
      })

      if (vista.tipoUsuario !== 'R') {
        saveTipoGrafica(
          globalChartType.viewType,
          globalChartType.chartType,
          tableroId,
          actualTab.id
        )
      }
    } else {
      if (vista.tipoUsuario !== 'R') {
        saveTipoGrafica(
          globalChartType.viewType,
          globalChartType.chartType,
          tableroId,
          null
        )
      }
      tabs.forEach((tab) => {
        tab.indicadores.forEach((indicador) => {
          // indicador.prevId = indicador.id
          // indicador.id = indicador.id * 2
          indicador.tipoGrafica =
            globalChartType.viewType === 'mixed'
              ? indicador.tipoGrafica
              : globalChartType.chartType
          indicador.tipoVista = globalChartType.viewType
        })
      })
    }
    const tabsCopy = [...tabs]
    globalChartType = null
    setTabs([])
    setTimeout(() => {
      setTabs(tabsCopy)
    }, 100);

  }

  function generateDataToAnalyze(tabs) {
    const data = { indicadores: [] }
    tabs.forEach((tab) => {
      tab.indicadores.forEach((indicador) => {
        const resultados = generateDataArray(indicador.resultados)
        if (resultados.length === 0) return
        data.indicadores.push({
          id: indicador.prevId,
          nombre: indicador.nombre,
          resultados
        })
      })
    })
    return data
  }

  function generateDataArray(resultados) {
    const data = { indicadores: [] }
    const cleanedResults = resultados
      .map((res) => {
        const properties = Object.keys(res)
        const valueProperty = properties.filter(
          (prop) => prop !== 'fecha' && !prop.startsWith('Meta')
        )[0]
        return res[valueProperty]
      })
      .filter((res) => res !== null)
    return cleanedResults
  }

  function saveVisibilidadEtiquetas(show, tableroId, tabId) {
    putVisbilidadEtiquetas(show, tableroId, tabId).then((res) => {
      if (res.status !== 200) {
        toast.error(
          'Ocurrió un error al actualizar las etiquetas en la base de datos'
        )
      }
    })
  }

  function saveTipoGrafica(viewType, chartType, tableroId, tabId) {
    putTipoVisualizacion(viewType, chartType, tableroId, tabId).then((res) => {
      if (res.status !== 200) {
        toast.error(
          'Ocurrió un error al actualizar el tipo de gráfica en la base de datos'
        )
      }
    })
  }

  async function handleIndicadorPropertyChange(property, indicadorId, index) {
    const tabsCopy = [...tabs]
    const tab =
      vista.tableroPersona
        ? tabsCopy[tabIndex - 1]
        : tabsCopy[tabIndex]
    const indicador = tab.indicadores.filter(
      (indicador, i) => indicador.id === indicadorId && i === index
    )[0]
    if (indicador) {
      console.log('indicador ', indicador)
      const detalle = {
        indicadorId: indicador.id,
        detalleId: indicador.detalleId,
        vistaIndicadorTabId: tab.id,
        [property]: !indicador[property]
      }
      if (vista.tipoUsuario !== 'R') {
        const resp = await putVistaIndicadorDetalle(detalle)
        if (resp.status !== 200) {
          return toast.error('Ocurrió un error al actualizar la vista')
        }
      }
      indicador[property] = !indicador[property]
      setTabs(tabsCopy)
    } else {
      toast.error('No se encontró el indicador')
    }
  }

  async function handleTabPropertyChange(property) {
    const tabsCopy = [...tabs]
    const tab =
      vista.tableroPersona
        ? tabsCopy[tabIndex - 1]
        : tabsCopy[tabIndex]
    if (tab) {
      const modifiedTab = {
        id: tab.id,
        [property]: !tab[property]
      }
      if (vista.tipoUsuario !== 'R') {
        const resp = await putVistaTab(modifiedTab)
        if (resp.status !== 200) {
          return toast.error('Ocurrió un error al actualizar la vista')
        }
      }
      tab[property] = !tab[property]
      setTabs(tabsCopy)
    } else {
      toast.error('No se encontró la pestaña')
    }
  }

  const handleSaveFilter = async (filter) => {
    // Buscar si ya existe el filtro en la lista visualFilters
    const index = visualFilters.findIndex((f) => f.columna === filter.columna)
    if (index !== -1) {
      visualFilters[index] = filter
    } else {
      visualFilters.push(filter)
    }

    setVisualFilters([...visualFilters])
    await putFiltros({ id: vista.id, filtros: visualFilters })
  }

  const getOpciones = (columna) => {
    const filtro = opcionesFiltros.find((filtro) => filtro.columna === columna)
    return filtro ? (filtro.valores || []) : []
  }

  const getValores = (filtro) => {
    if (filtro.esSimple) {
      return filtro.valores?.length ? filtro.valores[0] : null
    }
    return filtro.valores || []
  }

  const handleSaveFilterOp = async (value, columna) => {
    // Guardar una opcion de un filtro
    const index = visualFilters.findIndex((f) => f.columna === columna)
    if (index !== -1) {
      console.log('handleSaveFilterOp index ', index)
      console.log('handleSaveFilterOp value ', value)
      if (value === null || value === undefined || value.length === 0) {
        visualFilters[index].valores = []
        visualFilters[index].all_values = true
      }
      else if (visualFilters[index].esSimple) {
        visualFilters[index].valores = [value]
        visualFilters[index].all_values = false
      } else {
        visualFilters[index].valores = [...value]
        visualFilters[index].all_values = false
      }
      setVisualFilters([...visualFilters])
      updateFiltros([...visualFilters])
      await putFiltros({ id: vista.id, filtros: visualFilters })
    }
  }

  const handleCleanFilter = async (index) => {
    visualFilters.splice(index, 1)
    setVisualFilters([...visualFilters])
    updateFiltros([...visualFilters])
    await putFiltros({ id: vista.id, filtros: visualFilters })
  }


  const handleCleanFilters = async () => {
    visualFilters.forEach((filter) => {
      filter.valores = []
      filter.all_values = true
    })
    setVisualFilters([...visualFilters])
    updateFiltros([...visualFilters])
    await putFiltros({ id: vista.id, filtros: visualFilters })
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'center',
        gap: '1rem',
        flexWrap: 'wrap',
        margin: '1rem 0'
      }}
    >
      <DraggableDialog
        title='¿Desea descartar este borrador?'
        text='Confirme que desea descartar este borrador, los comentarios ingresados se borrarán de forma permanente.'
        open={confirmationModal}
        handleCloseProp={() => setConfirmationModal(false)}
        onConfirm={async () => { await actualizarEsBorrador(); setConfirmationModal(false); }}
      />
      <DraggableDialog
        title='¿Desea convertir en informe?'
        text='Confirme que desea convertir en informe.'
        open={confirmationModal2}
        handleCloseProp={() => setConfirmationModal2(false)}
        onConfirm={async () => { await createJsonData(); setConfirmationModal2(false); }}
      />
      <Paper
        elevation={3}
        sx={{
          padding: '20px',
          flex: '1',
          height: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          gap: '1rem'
        }}
      >
        <b style={{ textAlign: 'left' }}>Filtro de fechas</b>
        <div
          key={keyToReload}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '1rem',
            width: '100%'
          }}
        >
          {(vista && vista.reporteZafra) ? (
            <React.Fragment>
              <Autocomplete
                id="diazafra"
                value={diaZafraSelected}
                options={diasZafra}
                onChange={(event, newValue) => {
                  setDiaZafraSelected(newValue);
                }}
                sx={{ width: "100%" }}
                getOptionLabel={(option) => option.label}
                fullWidth
                renderInput={(params) => <TextField {...params} label="Dia Zafra" />}
              />
              <Autocomplete
                id="diazafra"
                value={turnoSelected}
                options={TURNOS}
                onChange={(event, newValue) => {
                  setTurnoSelected(newValue);
                }}
                sx={{ width: "100%" }}
                getOptionLabel={(option) => option.label}
                fullWidth
                renderInput={(params) => <TextField {...params} label="Turno" />}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <TextField
                id="fecha-ini"
                label="Desde"
                defaultValue={moment(fechaInicioConfirmada).format(
                  'YYYY-MM-DDTHH:mm'
                )}
                inputRef={fechaInicioRef}
                type="datetime-local"
                fullWidth
              />
              <TextField
                id="fecha-fin"
                label="Hasta"
                defaultValue={moment(fechaFinConfirmada).format('YYYY-MM-DDTHH:mm')}
                inputRef={fechaFinRef}
                type="datetime-local"
                sx={{ minWidth: '180px' }}
                fullWidth
              />
            </React.Fragment>
          )}
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <div />
            <Button variant="contained" color="success" onClick={saveViewChanges}>
              Actualizar
            </Button>
            <Tooltip title='Resetear fechas según periodos seleccionados'>
              <IconButton onClick={resetearFechasPeriodos}>
                <RestoreIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Paper>

      {datasetS3 && (
        <Paper
          elevation={3}
          sx={{
            padding: '20px',
            flex: '1',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            gap: '1rem'
          }}
        >
          <b style={{ textAlign: 'left' }}>Filtros de visualización</b>
          {visualFilters.map((filter, index) => (
            <Grid item container direction='row' sx={12}>
              {vista?.tipoUsuario === 'P' && (
                <Tooltip title='Eliminar filtro'>
                  <IconButton onClick={() => handleCleanFilter(index)}>
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
              )}
              <div style={{ display: 'flex', flex: 1 }}>
                <Autocomplete
                  id={filter.columna}
                  value={getValores(filter)}
                  options={getOpciones(filter.columna)}
                  onChange={(event, newValue) => {
                    console.log(`${filter.columna} - ${newValue}`)
                    handleSaveFilterOp(newValue, filter.columna)
                  }}
                  renderInput={(params) => <TextField {...params} label={filter.nombre} variant='outlined' />}
                  multiple={!filter.esSimple}
                  fullWidth
                />
              </div>
            </Grid>
          ))}
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <Tooltip title='Limpiar valores'>
              <IconButton onClick={handleCleanFilters}>
                <CleaningServicesIcon />
              </IconButton>
            </Tooltip>
            {vista?.tipoUsuario === 'P' && (
              <Button variant="outlined" color="primary" onClick={() => setOpenFilterFormModal(true)}>
                Nuevo filtro
              </Button>
            )}
          </div>
          <FilterFormModal
            open={openFilterFormModal}
            setOpen={setOpenFilterFormModal}
            dataset={datasetS3}
            onSave={handleSaveFilter}
          // filtros={filtros}
          />
        </Paper>
      )}

      <Paper
        elevation={3}
        sx={{
          padding: '20px',
          flex: '1',
          height: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          gap: '1rem'
        }}
      >
        <b style={{ textAlign: 'left' }}>Informe</b>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '1rem',
            flexWrap: 'wrap'
          }}
        >
          <TextField
            label="Nombre"
            value={nombreInforme}
            onChange={(e) => setNombreInforme(e.target.value)}
            fullWidth
          />
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
              justifyItems: 'center',
              flexDirection: 'column',
              width: '100%'
            }}
          >
            {!vista.tableroPersona && (
              <Button
                variant="contained"
                color="warning"
                onClick={() => vista.esBorrador ? setConfirmationModal(true) : actualizarEsBorrador()}
                style={{ marginBottom: 5, width: '80%' }}
              >
                {vista.esBorrador
                  ? 'Descartar borrador'
                  : 'Convertir en borrador'}
              </Button>
            )}
            <Button
              variant="contained"
              onClick={() => setConfirmationModal2(true)}
              style={{ width: '80%' }}
            >
              {vista.tableroPersona
                ? 'Generar evaluación'
                : 'Convertir en informe'}
            </Button>
          </div>
        </div>
      </Paper>
      <Paper
        elevation={3}
        sx={{
          padding: '20px',
          flex: '1',
          height: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly'
        }}
      >
        <b>Tipo de gráfico</b>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <div style={{ marginRight: '25px' }}>
            {actions.map((action) => (

              <Tooltip key={action.name} title={action.name} enterDelay={DEFAULT_DELAY}>
                <IconButton
                  aria-label="line chart"
                  // color="error"
                  onClick={() => {
                    globalChartType = {
                      chartType: action.type,
                      viewType: 'chart'
                    }
                    setOpenConfiguracionGlobal(true)
                  }}
                >
                  {action.icon}
                </IconButton>
              </Tooltip>

            ))}
          </div>
        </div>
        <b>Opciones adicionales</b>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <div>
            <Tooltip title={'Mostar valores'} enterDelay={DEFAULT_DELAY}>
              <IconButton
                aria-label="table"
                color={'primary'}
                onClick={() => {
                  globalShowValues = { show: true }
                  setOpenConfiguracionGlobal(true)
                }}
              >
                <AbcIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={'Ocultar valores'} enterDelay={DEFAULT_DELAY}>
              <IconButton
                aria-label="table"
                color={'default'}
                onClick={() => {
                  globalShowValues = { show: false }
                  setOpenConfiguracionGlobal(true)
                }}
              >
                <AbcIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Vista de tabla" enterDelay={DEFAULT_DELAY}>
              <IconButton
                aria-label="table"
                color="warning"
                onClick={() => {
                  globalChartType = {
                    chartType: 'line',
                    viewType: 'table'
                  }
                  setOpenConfiguracionGlobal(true)
                }}
              >
                <GridOnIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Vista de chart" enterDelay={DEFAULT_DELAY}>
              <IconButton
                aria-label="chart"
                color="warning"
                onClick={() => {
                  globalChartType = {
                    chartType: 'line',
                    viewType: 'chart'
                  }
                  setOpenConfiguracionGlobal(true)
                }}
              >
                <InsertChartIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Vista de chart y tabla" enterDelay={DEFAULT_DELAY}>
              <IconButton
                aria-label="mixed"
                color="warning"
                onClick={() => {
                  globalChartType = {
                    chartType: 'line',
                    viewType: 'mixed'
                  }
                  setOpenConfiguracionGlobal(true)
                }}
              >
                <PivotTableChartIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Paper>
      {((!vista.tableroPersona) ||
        ((vista.tableroPersona) && tabIndex !== 0)) && (
          <>
            <Paper
              elevation={3}
              sx={{
                padding: '20px',
                flex: '1',
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly'
              }}
            >
              <b> Indicadores </b>
              {tab?.indicadores.map((indicador, index) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '10px'
                  }}
                >
                  <div
                    style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
                  >
                    <i>{indicador.nombre}</i>
                    <Tooltip
                      title={indicador.mostrarGrafica ? 'Ocultar' : 'Mostrar'}
                      enterDelay={DEFAULT_DELAY}
                    >
                      <IconButton
                        aria-label="delete"
                        color={indicador.mostrarGrafica ? 'primary' : 'error'}
                        onClick={() => {
                          handleIndicadorPropertyChange(
                            'mostrarGrafica',
                            indicador.id,
                            index
                          )
                        }}
                        sx={{
                          dispaly: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center'
                        }}
                      >
                        {indicador.mostrarGrafica ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                        <span style={{ fontSize: '8px' }}>Gráfica</span>
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title={indicador.mostrarResumen ? 'Ocultar' : 'Mostrar'}
                      enterDelay={DEFAULT_DELAY}
                    >
                      <IconButton
                        aria-label="config"
                        color={indicador.mostrarResumen ? 'primary' : 'error'}
                        onClick={() => {
                          handleIndicadorPropertyChange(
                            'mostrarResumen',
                            indicador.id,
                            index
                          )
                        }}
                        sx={{
                          dispaly: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center'
                        }}
                      >
                        {indicador.mostrarResumen ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                        <span style={{ fontSize: '8px' }}>Resumen</span>
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              ))}
            </Paper>

            <Paper
              elevation={3}
              sx={{
                padding: '20px',
                height: 'auto',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <b> Tabla pivote </b>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <Tooltip
                  title={tab?.mostrarPivote ? 'Ocultar' : 'Mostrar'}
                  enterDelay={DEFAULT_DELAY}
                  sx={{
                    display: 'flex',
                    justifySelf: 'center',
                    alignSelf: 'center'
                  }}
                >
                  <IconButton
                    aria-label="delete"
                    color={tab?.mostrarPivote ? 'primary' : 'error'}
                    onClick={() => {
                      handleTabPropertyChange('mostrarPivote')
                    }}
                  >
                    {tab?.mostrarPivote ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                  <i>Tabla</i>
                </Tooltip>
                <Tooltip
                  title={tab?.mostrarPivoteGraph ? 'Ocultar' : 'Mostrar'}
                  enterDelay={DEFAULT_DELAY}
                  sx={{
                    display: 'flex',
                    justifySelf: 'center',
                    alignSelf: 'center'
                  }}
                >
                  <IconButton
                    aria-label="delete"
                    color={tab?.mostrarPivoteGraph ? 'primary' : 'error'}
                    onClick={() => {
                      handleTabPropertyChange('mostrarPivoteGraph')
                    }}
                  >
                    {tab?.mostrarPivoteGraph ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                  <i>Gráfico</i>
                </Tooltip>
              </div>
            </Paper>
          </>
        )}
      <ModalOpcionesVistaGlobal
        open={openConfiguracionGlobal}
        handleClose={() => setOpenConfiguracionGlobal(false)}
        aplicarATodaLaVista={() => aplicarCambiosGlobales(false)}
        aplicarAPestanaActual={() => aplicarCambiosGlobales(true)}
      />
    </div>
  )
}

import React from 'react'
import { IconButton, Tooltip, Menu, MenuItem } from '@mui/material'
import { DEFAULT_DELAY } from '../Utils/config'
import { MODO_INTEGRACION_GLOBAL } from '../Utils/enums'
import { GRAFICAS_AGRUPADAS, AGRUPADORES } from '../Utils/graphConstants'
import GridOnIcon from '@mui/icons-material/GridOn'
import InsertChartIcon from '@mui/icons-material/InsertChart'
import PivotTableChartIcon from '@mui/icons-material/PivotTableChart'
import AbcIcon from '@mui/icons-material/Abc'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import ReportIcon from '@mui/icons-material/Report'
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat'
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import ToolbarChart from './toolbarChart'
import styles from './graph.module.css'

export function ChartControls({
  operacionIntegracion,
  showCumulativeChart,
  additionalButtons,
  showValueTag,
  showAnalysis,
  fullScreen,
  setFullScreen,
  detalleIdProp,
  setChartType,
  tipoVista,
  setTipoVista,
  actualizarVistaTipoGraficaFn,
  setShowValueTag,
  handleCumulativeButtonClick,
  chartType,
  checkIfAnalysisIsCorrect,
  setModalOpen,
  performStatisticaAnalysis,
  agrupado,
  setAgrupadorGrafica,
  agrupadorGrafica,
  zoomLock,
  handleZoomLock
}) {

  console.log('Render ChartControls')

  function getChartIcon() {
    if (showCumulativeChart) {
      return <TrendingUpIcon />
    }
    return <TrendingFlatIcon />
  }

  const handleChartTypeChange = React.useCallback((type) => {
    setChartType(type)
    setTipoVista('chart')
    try {
      actualizarVistaTipoGraficaFn({
        tipoVista: 'chart',
        tipoGrafica: type,
        id: detalleIdProp
      })
    } catch (error) { }
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  if (fullScreen)
    return (
      <Tooltip title="Cerrar pantalla completa" enterDelay={DEFAULT_DELAY}>
        <IconButton
          color="secondary"
          sx={{ float: 'right' }}
          onClick={() => {
            setFullScreen(false)
          }}
        >
          <CloseFullscreenIcon />
        </IconButton>
      </Tooltip>
    )

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <div style={{ display: 'flex', flex: 1, paddingLeft: 3 }}>
        {operacionIntegracion === MODO_INTEGRACION_GLOBAL.SUMATORIA && (
          <Tooltip
            title={
              showCumulativeChart
                ? 'Visualizar gráfica lineal'
                : 'Visualizar gráfica acumulativa'
            }
            enterDelay={DEFAULT_DELAY}
          >
            <IconButton color="primary" onClick={handleCumulativeButtonClick}>
              {getChartIcon()}
            </IconButton>
          </Tooltip>
        )}
        <ToolbarChart tipoVista={chartType} setTipoVista={handleChartTypeChange} />
      </div>
      {additionalButtons.length > 0 &&
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
          {additionalButtons.map((button, index) => {
            return (
              <Tooltip
                title={button.title}
                enterDelay={DEFAULT_DELAY}
                key={`additional-button-${index}`}
              >
                <IconButton color="primary" onClick={button.onClick}>
                  {button.icon}
                </IconButton>
              </Tooltip>
            )
          })}
        </div>
      }
      <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
        <Tooltip
          title={zoomLock ? 'Habilitar zoom' : 'Desabilitar zoom'}
          enterDelay={DEFAULT_DELAY}
        >
          <IconButton
            color={showValueTag ? 'primary' : 'default'}
            sx={{ position: 'relative' }}
            onClick={handleZoomLock}
          >
            <ZoomInMapIcon sx={{ color: '#68BDE1', fontSize: 22, zIndex: 1 }} />
            {zoomLock && (
              <div style={{ position: 'absolute', zIndex: 2, display: 'flex' }}>
                <HorizontalRuleIcon  sx={{ color: '#DB5F29', fontSize: 35, opacity: 0.9, transform: 'rotate(45deg)' }} />
              </div>
            )}
          </IconButton>
        </Tooltip>
        {GRAFICAS_AGRUPADAS.includes(chartType) && (
          <Tooltip title="Agrupar gráfica por">
            <IconButton color={agrupadorGrafica ? "primary" : 'default'} onClick={handleMenuClick}>
              <EventRepeatIcon />
            </IconButton>
          </Tooltip>
        )}
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
        >
          {AGRUPADORES.map((agrupador, index) => (
            <MenuItem
              key={`agrupador-${index}`}
              selected={agrupadorGrafica === agrupador.value}
              sx={{ '&.Mui-selected': { color: '#1565c0' } }}
              onClick={() => {
                try {
                  actualizarVistaTipoGraficaFn({
                    agrupadorGrafica: agrupador.value,
                    id: detalleIdProp
                  })
                  setAgrupadorGrafica(agrupador.value)
                } catch (error) {
                  console.error(error)
                }
                handleMenuClose();
              }}
            >{agrupador.label}</MenuItem>
          ))}
        </Menu>
        <Tooltip
          title={showValueTag ? 'Ocultar valores' : 'Mostrar valores'}
          enterDelay={DEFAULT_DELAY}
        >
          <IconButton
            color={showValueTag ? 'primary' : 'default'}
            onClick={() => {
              try {
                actualizarVistaTipoGraficaFn({
                  mostrarEtiquetas: !showValueTag,
                  id: detalleIdProp
                })
                setShowValueTag(!showValueTag)
              } catch (error) {
                console.error(error)
              }
            }}
          >
            <AbcIcon />
          </IconButton>
        </Tooltip>
        {!agrupado && showAnalysis && !checkIfAnalysisIsCorrect() && (
          <Tooltip
            title={'Mostrar riesgos encontrados'}
            enterDelay={DEFAULT_DELAY}
          >
            <IconButton
              className={styles['blink']}
              color={showAnalysis ? 'error' : 'default'}
              onClick={() => setModalOpen(true)}
            >
              <ReportIcon />
            </IconButton>
          </Tooltip>
        )}
        {!agrupado && (
          <Tooltip
            title={
              showAnalysis ? 'Ocultar análisis' : 'Mostar análisis estadístico'
            }
            enterDelay={DEFAULT_DELAY}
          >
            <IconButton
              color={showAnalysis ? 'primary' : 'default'}
              onClick={(e) => {
                try {
                  actualizarVistaTipoGraficaFn({
                    mostrarAnalisis: !showAnalysis,
                    id: detalleIdProp
                  })
                  performStatisticaAnalysis(!showAnalysis)
                } catch (error) {
                  console.error(error)
                }
              }}
            >
              <QueryStatsIcon />
            </IconButton>
          </Tooltip>
        )}
        {!agrupado && <Tooltip title="Vista de tabla" enterDelay={DEFAULT_DELAY}>
          <IconButton
            color={tipoVista === 'table' ? 'warning' : 'default'}
            onClick={() => {
              setTipoVista('table')
              try {
                actualizarVistaTipoGraficaFn({
                  tipoVista: 'table',
                  tipoGrafica: chartType,
                  id: detalleIdProp
                })
              } catch (error) { }
            }}
          >
            <GridOnIcon />
          </IconButton>
        </Tooltip>}
        {!agrupado && <Tooltip title="Vista de chart" enterDelay={DEFAULT_DELAY}>
          <IconButton
            color={tipoVista === 'chart' ? 'warning' : 'default'}
            onClick={() => {
              setTipoVista(tipoVista == 'chart' ? 'null' : 'chart')
              try {
                actualizarVistaTipoGraficaFn({
                  tipoVista: tipoVista == 'chart' ? 'null' : 'chart',
                  tipoGrafica: chartType,
                  id: detalleIdProp
                })
              } catch (error) { }
            }}
          >
            <InsertChartIcon />
          </IconButton>
        </Tooltip>}
        {!agrupado && <Tooltip title="Vista de chart y tabla" enterDelay={DEFAULT_DELAY}>
          <IconButton
            color={tipoVista === 'mixed' ? 'warning' : 'default'}
            onClick={() => {
              setTipoVista('mixed')
              try {
                actualizarVistaTipoGraficaFn({
                  tipoVista: 'mixed',
                  tipoGrafica: chartType,
                  id: detalleIdProp
                })
              } catch (error) { }
            }}
          >
            <PivotTableChartIcon />
          </IconButton>
        </Tooltip>}

        <Tooltip title="Pantalla completa" enterDelay={DEFAULT_DELAY}>
          <IconButton
            color="secondary"
            onClick={() => {
              setFullScreen(true)
            }}
          >
            <OpenInFullIcon />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  )
}

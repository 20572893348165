import React from 'react'
import { copiarIndicador, moverIndicador } from '../Api/indicadores.api'
import { toast } from 'react-toastify'
import { Button } from '@mui/material'
import { modosIntegracion, TIPOS_NODO } from '../Utils/enums'
import ProcessTreeWithKPI from '../TreeView/ProcessTreeWithKPI'
import Confirm from './confirm'
import { NodoContext } from '../Context/nodoContext'
import { PeriodoContext } from '../Context/periodoContext'

export default function CopiarIndicadorForm({ indicadorActivo, handleCloseModal }) {
  const [nodoDestino, setNodoDestino] = React.useState(null)
  const [confirmDialog, setConfirmDialog] = React.useState({})
  const { getSelected } = React.useContext(PeriodoContext);

  const { setLoading } = React.useContext(NodoContext)

  function handleCopyMove(isMove = false) {
    setLoading(true)
    if (!indicadorActivo) {
      setLoading(false)
      return toast.warn('No se ha seleccionado ningún indicador')
    }
    if (nodoDestino === null) {
      setLoading(false)
      return toast.warn('Debe seleccionar un proceso o actividad destino')
    }
    if (
      nodoDestino.tipoNodoId === TIPOS_NODO.PROCESO &&
      indicadorActivo.modoIntegracionId !== modosIntegracion.calculado
    ) {
      setLoading(false)
      return toast.warn(
        'Solo se pueden copiar indicadores calculados a procesos'
      )
    }
    const settings = {
      nodoId: nodoDestino.id,
      indicadorId: indicadorActivo.id,
      agrupado: indicadorActivo.agrupado,
      periodos: getSelected(),
    }
    if (isMove) {
      moverIndicador(settings).then((response) => {
        if (response.status === 201) {
          toast.success('indicador movido con éxito')
          handleCloseModal();
        } else {
          toast.error('Error al mover el indicador')
        }
      }).finally(() => setLoading(false))
    } else {
      copiarIndicador(settings).then((response) => {
        if (response.status === 201) {
          toast.success('indicador copiado con éxito')
          handleCloseModal();
        } else {
          toast.error('Error al copiar el indicador')
        }
      }).finally(() => setLoading(false))
    }
  }

  function handleSelect(nodo) {

    setNodoDestino(nodo)
  }

  return (
    <div>
      <br />
      {nodoDestino ? (
        <p>
          {nodoDestino.tipoNodoId === TIPOS_NODO.PROCESO
            ? 'Proceso destino:'
            : 'Actividad destino:'}{' '}
          <b>{nodoDestino.nombre}</b>
        </p>
      ) : (
        <p>Seleccione un proceso o actividad destino</p>
      )}
      <div style={{ maxHeight: 'calc(100vh*0.7)', overflow: 'auto' }}>
        {/* <ProcessTree onNodeClick={handleSelect} fontColor="black" /> */}
        <ProcessTreeWithKPI
          fontColor="black"
          onNodeClick={handleSelect}
          draggable={false}
          showAgrupaciones={false}
          showIndicadoresAgrupados={true}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: '10px', float: 'right' }}
          onClick={() => {
            setConfirmDialog({
              open: true,
              text: '¿Está seguro de copiar el indicador?',
              onConfirm: () => {
                handleCopyMove(false)
                setConfirmDialog({})
              },
            })
          }}
        >
          Copiar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          style={{ marginTop: '10px', float: 'right', marginLeft: '10px' }}
          onClick={() => {
            setConfirmDialog({
              open: true,
              title: '¿Está seguro de mover el indicador?',
              text: 'Puede que la configuración del nodo destino no coincida con el nodo actual',
              onConfirm: () => {
                handleCopyMove(true)
                setConfirmDialog({})
              },
            })
          }}
        >
          Mover
        </Button>
        <Confirm
          title={confirmDialog.title || 'Confirmación'}
          open={confirmDialog.open}
          text={confirmDialog.text || '¿Está seguro que desea realizar esta acción?'}
          handleCloseProp={() => setConfirmDialog({ open: false })}
          onConfirm={confirmDialog.onConfirm || (() => {})} 
        />
      </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import {
  Chart,
  Size,
  Series,
  Reduction,
  CommonSeriesSettings,
  Export,
  Legend,
  Margin,
  Tooltip,
  Label,
  ZoomAndPan,
  ScrollBar,
  ArgumentAxis,
  ValueAxis,
  Point,
  ConstantLine,
  Annotation,
  Title
} from 'devextreme-react/chart'
import { toast } from 'react-toastify'
import ChartDSControls from './ChartDSControls'
import { ChartSkeleton } from '../ChartSkeleton'
import { CHARTS_COLORS } from '../../Utils/enums'
import { graphDataset } from '../../Api/vistaDatasets'
import { LayoutContext } from '../../Context/layoutContext'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import { CHART_MIN_WIDTH, PALETA_GRAFICAS, TITULOS_GRAFICAS, TOTAL_LABELS } from '../../Utils/graphConstants'
import { Button, Paper, Grid, IconButton } from '@mui/material'
import styles from '../graph.module.css'
import { TooltipTemplateDS } from './TooltipTemplateDs'

export default function GraphDS({
  dataset,
  visualFilters,
  detalleIdProp,
  mostrarEtiquetasProp,
  height = 500,
  maxWidth = 100,
  minWidth = 100,
  showToolButtons = true,
  configGrafica,
  allowComments,
  seriesOcultasProp,
  agregarListaComentarios,
  fechaInicioConfirmada,
  fechaFinConfirmada,
  actualizarVistaTipoGraficaFn = () => { },
  ...props
}) {

  const [data, setData] = useState(null)
  const [leyendas, setLeyendas] = useState([])
  const [chartType, setChartType] = useState(null)
  const [fullScreen, setFullScreen] = useState(false)
  const [configChart, setConfigChart] = useState(null)
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [showValueTag, setShowValueTag] = useState(mostrarEtiquetasProp || false)
  const [seriesVisibility, setSeriesVisibility] = useState({});
  const [chartError, setChartError] = useState(null)

  const chartRef = React.useRef()
  const { isDrawerOpen } = React.useContext(LayoutContext)

  useEffect(() => {
    if (configGrafica) {
      // Convertir a json el string de configuración
      const config = JSON.parse(configGrafica)
      setChartType(config.tipoGrafica)
      setConfigChart(config)
    }
  }, [configGrafica])

  useEffect(() => {
    if (dataset && configChart) {
      console.log('visualFilters ', visualFilters)
      obtenerData()
    }
  }, [dataset, configChart, visualFilters])

  function armarSeriesDict(leyendas) {
    const seriesDict = {}
    leyendas.forEach((leyenda) => {
      seriesDict[leyenda] = !seriesOcultasProp.includes(leyenda)
    })
    setSeriesVisibility(seriesDict)
  }

  async function obtenerData() {
    try {
      setIsDataLoading(true)
      const body = {
        s3_key: dataset,
        config: configChart,
        visualFilters,
      }
      const response = await graphDataset(body)

      if (response.status === 200 || response.status === 201) {
        const { leyendas, resultados } = response.body

        setData(resultados)
        setLeyendas(leyendas)
        armarSeriesDict(leyendas)
        setChartError(null)
      } else {
        console.log('Error al obtener data', response)
        setChartError(new Error('Error al obtener data'))
      }
    } catch (error) {
      console.error('Error al obtener data', error)
      console.log('Error al obtener data', error)
      setChartError(error)
    } finally {
      setIsDataLoading(false)
    }
  }

  function getCorrectWidth() {
    const width = isDrawerOpen ? minWidth : maxWidth
    if (width < CHART_MIN_WIDTH) return CHART_MIN_WIDTH
    return width
  }

  function getFullScreenProps() {
    if (fullScreen) {
      return ({
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 2000,
        margin: 0,
        width: window.innerWidth
      })
    }
    return {}
  }

  function refreshChart(timeout = 50) {
    setIsDataLoading(true)
    setTimeout(() => {
      setIsDataLoading(false)
    }, timeout)
  }

  const getTipoGraficaBase = () => {
    if (chartType === 'distribucion') return 'line'
    if (chartType === 'histograma') return 'bar'
    return chartType
  }

  function hideTooltips() {
    chartRef.current.instance.getAllSeries().forEach((serie) => {
      serie.getAllPoints().forEach((point) => {
        point.hideTooltip()
      })
    })
  }

  const customizeLabel = (arg) => {
    console.log('customizeLabel ', arg)
    if (showValueTag) {
      const totalItems = (data).length
      const labels = fullScreen ? TOTAL_LABELS * 2 : TOTAL_LABELS
      const step = Math.ceil(totalItems / labels);
      if (totalItems > labels && arg.index % step !== 0) return { visible: false }
      return {
        visible: true,
        backgroundColor: arg?.series ? PALETA_GRAFICAS[arg.series.index] : '#295F98',
        customizeText(e) {
          return `${e.valueText.replace(/(\d+\.\d{1,4})\d*/, '$1')}`;
        },
      };
    }
    return null;
  };

  function ocultarMostrarSerie(_seriesVisibility) {
    try {
      const seriesOcultas = Object.keys(_seriesVisibility).filter((key) => !_seriesVisibility[key])
      actualizarVistaTipoGraficaFn({
        id: detalleIdProp,
        seriesOcultas: seriesOcultas.join(',')
      })
    } catch (error) { console.error(error) }
  }

  const onLegendClick = (e) => {
    const seriesName = e.target.name;
    const _seriesVisibility = { ...seriesVisibility };
    _seriesVisibility[seriesName] = !_seriesVisibility[seriesName];
    ocultarMostrarSerie(_seriesVisibility);
    setSeriesVisibility(_seriesVisibility);
  };

  const onConfigChance = (data) => {
    refreshChart(500)
    setConfigChart(data)
    setChartType(data.tipoGrafica)
    try {
      actualizarVistaTipoGraficaFn({
        id: detalleIdProp,
        configGrafica: data,
        tipoGrafica: data.tipoGrafica,
        nombrePersonalizado: data.titulo
      })
    } catch (error) { console.error(error) }
  }

  return (
    <React.Fragment>
      <Grid
        component={Paper}
        container
        elevation={5}
        style={{ flex: 1, display: 'flex' }}
        sx={{
          m: 1,
          width: getCorrectWidth(),
          minHeight: fullScreen ? window.innerHeight : height - 100,
          height: 'auto',
          maxHeight: fullScreen ? window.innerHeight : height + 100,
          overflowY: 'scroll',
          ...getFullScreenProps()
        }}
      // onDoubleClick={onDoubleClick}
      >
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          {showToolButtons ? (
            <ChartDSControls
              detalleIdProp={detalleIdProp}
              showValueTag={showValueTag}
              setShowValueTag={setShowValueTag}
              fullScreen={fullScreen}
              setFullScreen={(value) => { setFullScreen(value); refreshChart() }}
              chartType={chartType}
              setChartType={setChartType}
              actualizarVistaTipoGraficaFn={actualizarVistaTipoGraficaFn}
              handleConfigChance={onConfigChance}
              configChart={configChart}
              datasetKey={dataset}
              isDataLoading={isDataLoading}
              setIsDataLoading={setIsDataLoading}
            />
          ) : (
            <div className={styles['ajuste-mobile']}>
              <IconButton
                color="secondary"
                onClick={() => {
                  setFullScreen(!fullScreen)
                }}
                title='Pantalla completa'
              >
                <OpenInFullIcon />
              </IconButton>
            </div>
          )}
          {isDataLoading && <ChartSkeleton message='Renderizando gráfica' />}
          {(data && !isDataLoading && !chartError) && (
            <GraficaMemo
              chartRef={chartRef}
              palette={PALETA_GRAFICAS}
              data={data}
              leyendas={leyendas}
              chartType={chartType}
              showValueTag={showValueTag}
              configChart={configChart}
              fullScreen={fullScreen}
              allowComments={allowComments}
              getTitulo={() => configChart.titulo}
              getTipoGraficaBase={getTipoGraficaBase}
              hideTooltips={hideTooltips}
              customizeLabel={customizeLabel}
              seriesOcultasProp={seriesOcultasProp}
              onLegendClick={onLegendClick}
              seriesVisibility={seriesVisibility}
            />
          )}
          {chartError && (
            <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <p style={{ color: 'red' }}>Error al renderizar la gráfica</p>
            </div>
          )}
        </div>
      </Grid>
    </React.Fragment>
  )
}

const GraficaMemo = React.memo(function GraficaMemo({
  chartRef, palette, data, leyendas, chartType, showValueTag, configChart, fullScreen,
  allowComments, getTitulo, getTipoGraficaBase, hideTooltips, customizeLabel, seriesOcultasProp,
  onLegendClick, seriesVisibility
}) {
  console.log('GraficaMemo renderizar')
  const [scrollVisible, setScrollVisible] = useState(false);

  const handleZoomEnd = (e) => {
    setScrollVisible(e.shift !== 0);
  };

  const getArgumentField = () => 'EjeX'

  function onPointClick({ target: point }) {
    point.showTooltip()
  }

  return (
    <Chart
      palette={palette}
      dataSource={data}
      width={'100%'}
      ref={chartRef}
      redrawOnResize={true}
      onPointClick={onPointClick}
      customizeLabel={customizeLabel}
      onLegendClick={onLegendClick}
      animation={false}
      lazyRendering={true}
      onZoomEnd={handleZoomEnd}

    >
      <Title text={getTitulo()} subtitle={allowComments ? TITULOS_GRAFICAS[chartType] : null} />
      <Size
        height={fullScreen ? window.innerHeight - 50 : undefined}
        width={'100%'}
      />
      <CommonSeriesSettings
        argumentField={getArgumentField()}
        type={getTipoGraficaBase()}
      />
      {leyendas.map((leyenda, index) => (
        <Series
          key={index}
          width={4}
          name={leyenda}
          dashStyle={'solid'}
          valueField={leyenda}
          visible={seriesVisibility[leyenda]}
          type={getTipoGraficaBase()}
        >
          <Point visible={true}></Point>
          <Reduction color={palette[index]} />
        </Series>
      ))}
      <Margin bottom={20} />
      <Legend
        verticalAlignment="bottom"
        horizontalAlignment="center"
        itemTextPosition="bottom"
      />
      <Export enabled={true} />
      <Tooltip
        enabled={false}
        contentComponent={(props) => (
          <TooltipTemplateDS hideTooltips={hideTooltips} {...props} />
        )}
        interactive={true}
      />
      <ZoomAndPan
        panKey="shift"
        dragToZoom={true}
        valueAxis="both"
        argumentAxis="both"
      />
      <ScrollBar
        opacity={0.5}
        position='bottom'
        visible={scrollVisible}
        width={fullScreen ? 10 : 5}
      />
    </Chart>
  )
})

import React, { useEffect, useRef, useState } from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { toast } from 'react-toastify'
import { NodoContext } from '../Context/nodoContext'
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Tooltip,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Alert,
  AlertTitle,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch
} from '@mui/material'
import {
  getModosIntegracion,
  getModosIntegracionGlobal
} from '../Api/modoIntergacion.api'
import { getUnidadMedidaIndicador, getUnidadesMedida } from '../Api/unidadMedidaIndicador.api'
import { postIndicador, putIndicador, putConfMCU, ejecutarFlujo } from '../Api/indicadores.api'
import { getOperacionesIntegracion } from '../Api/operacionesIntegracion.api'
import CalculateIcon from '@mui/icons-material/Calculate'
import ScheduleIcon from '@mui/icons-material/Schedule'
import DeleteIcon from '@mui/icons-material/Delete'
import { ModalCalculadora } from './modalCalculadora'
import { nodeType } from '../Utils/node-types'
import { AccordionSection } from '../general-components/accordionSection'
import { AccordionOptions } from './accordionOptions'
import { useIndicador } from '../Hooks/useIndicador'
import { SerieItem } from './serieItem'
import InfoIcon from '@mui/icons-material/Info';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { LoadingButton } from '@mui/lab'
import { TIPOS_PERIODOS_INDICADOR, FRECUENCIAS_EVIDENCIA, FRECUENCIA_EVIDENCIA } from '../Utils/enums';
import Confirm from "../general-components/confirm";
import { PeriodoContext } from '../Context/periodoContext'
import { LineaItem } from './lineaItem'
import TablaFormatos from './tablaFormatos'
import axios from 'axios'

import Cron, { HEADER } from 'react-cron-generator'
import { cronTranslation } from '../Utils/utilities'

const INDICADOR_CALCULADO = 3
const tituloPublico =
  'Si no es publico, no se mostrará a menos que usted sea parte de la RACI'
const tituloAplicaR = 'Permite crear evaluaciones para el responsable ejecutivo'
const tituloAplicaA = 'Permite crear evaluaciones para el responsable directivo'
const tituloAplicaC = 'Permite crear evaluaciones para el consultado'
const tituloAplicaI = 'Permite crear evaluaciones para el informado'
const tituloMetaInversa =
  'En las metas inversas se busca que el resultado no sobrepase el valor de la meta'

function IndicadoresForm({ setOpen, updateIndicadores, indicadorMetadata }) {
  const { indicador } = useIndicador({ indicadorId: indicadorMetadata.id, agrupado: indicadorMetadata.agrupado })
  const { nodoActual, reloadTree, setLoading } = React.useContext(NodoContext)
  const { getUnicoPeriodoSeleccionado } = React.useContext(PeriodoContext)
  const [nombre, setNombre] = useState('')
  const [IndicadorId, setIndicadorId] = useState('')
  const [descripcion, setDescripcion] = useState('')
  const [unidadDeMedida, setUnidadDeMedida] = useState('')
  const [fuenteDeDatos, setFuenteDeDatos] = useState('')
  const [modoIntegracionId, setModoIntegracionId] = useState(3)
  const [modoIntegracionGlobalId, setModoIntegracionGlobalId] = useState('')
  const [metaConsolidada, setMetaConsolidada] = useState(0)
  const [metaPorMedicion, setMetaPorMedicion] = useState(0)
  const [metaIndividualPorMedicion, setMetaIndividualPorMedicion] = useState(0)
  const [metaIndividualConsolidada, setMetaIndividualConsolidada] = useState(0)
  const [valorFrecuencia, setValorFrecuencia] = useState(0)
  const [unidadMedidaId, setUnidadMedidaId] = useState('')
  const [tipoMedicionPeriodo, setTipoMedicionPeriodo] = useState(TIPOS_PERIODOS_INDICADOR.COMPLETO)
  const [frecuenciaEvidencia, setFrecuenciaEvidencia] = useState(FRECUENCIA_EVIDENCIA.ULTIMA)
  const [medicionIniciaLunes, setMedicionIniciaLunes] = useState(false)
  const [etiquetaEjeX, setEtiquetaEjeX] = useState(false)
  const [graficarEjeXDiscreto, setGraficarEjeXDiscreto] = useState(false)
  const [formatoFecha, setFormatoFecha] = useState(null)
  const [errorFormatoFecha, setErrorFormatoFecha] = useState(false);
  const [openPanelFormatos, setOpenPanelFormatos] = useState(false);
  const [operacionIntegracionId, setOperacionIntegracionId] = useState('')
  const [activo, setActivo] = useState(true)
  const [publico, setPublico] = useState(true)
  const [permiteSobregiro, setPermiteSobregiro] = useState(false)
  const [limiteSobregiro, setLimiteSobregiro] = useState(null)
  const [modosIntegracion, setModosIntegracion] = useState([])
  const [modosIntegracionGlobal, setModosIntegracionGlobal] = useState([])
  const [frecuenciasIndicador, setFrecuenciasIndicador] = useState([])
  const [unidadesMedida, setUnidadesMedida] = useState([])
  const [operacionesIntegracion, setOperacionesIntegracion] = useState([])
  const [abrirCalculadora, setAbrirCalculadora] = useState(false)
  const [expresion, setExpresion] = React.useState('')
  const [aplicaEvaluacionR, setAplicaEvaluacionR] = useState(false)
  const [aplicaEvaluacionA, setAplicaEvaluacionA] = useState(false)
  const [aplicaEvaluacionC, setAplicaEvaluacionC] = useState(false)
  const [aplicaEvaluacionI, setAplicaEvaluacionI] = useState(false)
  const [metaInversa, setMetaInversa] = useState(false)
  const [expanded, setExpanded] = React.useState({
    definicion: false,
    medicion: false,
    series: false,
    integracion: false,
    metas: false,
    lineasReferencia: false,
    visibilidad: false
  })
  const [habilitarSeries, setHabilitarSeries] = useState(false)
  const [habilitarSeriesOriginal, setHabilitarSeriesOriginal] = useState(false)
  const [disableButtons, setDisableButtons] = React.useState(false)
  const [series, setSeries] = useState([])
  const [lineasReferencia, setLineasReferencia] = useState([])
  const [modalCheckSeriesConfirm, setModalCheckSeriesConfirm] = useState(false)
  const [checkSeriesConfirm, setCheckSeriesConfirm] = useState(null)
  const [modalDeleteSerieConfirm, setModalDeleteSerieConfirm] = useState(false)
  const [deleteSerieConfirm, setDeleteSerieConfirm] = useState(null)
  const serieRef = useRef(null)
  const lineaNombreRef = useRef(null)
  const lineaValorRef = useRef(null)
  const esEdicion = indicadorMetadata.id ? true : false
  const [openMCUCron, setOpenMCUCron] = useState(false)
  const [openMCUDeleteTask, setOpenMCUDeleteTask] = useState(false)
  const [flujosMCU, setFlujosMCU] = useState([])
  const [confMCU, setConfMCU] = useState({ variables: [] })
  const [tieneMetas, setTieneMetas] = useState(true)
  const [confirmFlowExecutionDialog, setConfirmFlowExecutionDialog] = useState(false)

  console.log({ unidadDeMedida })

  const handleChange = (panel) => {
    setExpanded({ ...expanded, [panel]: !expanded[panel] })
  }

  const handleExpandirTodos = () => {
    setExpanded({
      definicion: true,
      medicion: true,
      series: true,
      integracion: true,
      metas: true,
      visibilidad: true
    })
  }

  function handleContraerTodos() {
    setExpanded({
      definicion: false,
      medicion: false,
      series: false,
      integracion: false,
      metas: false,
      visibilidad: false
    })
  }

  useEffect(() => {
    console.log({ indicador })
    setConfMCU(indicador.confMCU || { variables: [] })
    setNombre(indicador.nombre || '')
    setIndicadorId(indicador.IndicadorId || '')
    setDescripcion(indicador.descripcion || '')
    setUnidadDeMedida(indicador.unidadDeMedida || '')
    setFuenteDeDatos(indicador.fuenteDeDatos || '')
    setModoIntegracionId(
      indicador.modoIntegracionId ||
        nodoActual.tipoNodoId === nodeType.actividad
        ? indicador.modoIntegracionId
        : 3
    )
    setModoIntegracionGlobalId(indicador.modoIntegracionGlobalId || '')
    setMetaConsolidada(indicador.metaConsolidada || 0)
    setMetaPorMedicion(indicador.metaPorMedicion || 0)
    setMetaIndividualPorMedicion(indicador.metaIndividualPorMedicion || 0)
    setMetaIndividualConsolidada(indicador.metaIndividualConsolidada || 0)
    setValorFrecuencia(indicador.valorFrecuencia || 0)
    setUnidadMedidaId(indicador.unidadMedidaId || '')
    setTipoMedicionPeriodo(indicador.tipoMedicionPeriodo)
    setFrecuenciaEvidencia(indicador.frecuenciaEvidencia)
    setMedicionIniciaLunes(indicador.medicionIniciaLunes)
    setEtiquetaEjeX(indicador.etiquetaEjeX)
    setGraficarEjeXDiscreto(indicador.graficarEjeXDiscreto)
    setFormatoFecha(indicador.formatoFecha)
    setOperacionIntegracionId(indicador.operacionIntegracionId || '')
    setActivo(indicador.activo ?? true)
    setPublico(indicador.publico ?? true)
    setPermiteSobregiro(indicador.permiteSobregiro ?? false)
    setLimiteSobregiro(indicador.limiteSobregiro || null)
    setExpresion(indicador.formula || '')
    setAplicaEvaluacionR(indicador.aplicaEvaluacionR || false)
    setAplicaEvaluacionA(indicador.aplicaEvaluacionA || false)
    setAplicaEvaluacionC(indicador.aplicaEvaluacionC || false)
    setAplicaEvaluacionI(indicador.aplicaEvaluacionI || false)
    setMetaInversa(indicador.metaInversa ?? false)
    setHabilitarSeries(indicador.habilitarSeries ?? false)
    setHabilitarSeriesOriginal(indicador.habilitarSeries ?? false)
    setSeries(indicador.series || [])
    setLineasReferencia(indicador.lineasReferencia || [])
    setTieneMetas(indicador.tieneMetas == undefined ? true : indicador.tieneMetas)
  }, [indicador])

  useEffect(() => {
    updateModosIntegracion()
    updateModosIntegracionGlobal()
    updateFrecuenciasIndicador()
    updateUnidadesMedida()
    updateOperacionesIntegracion()
    if (nodoActual.tipoNodoId === nodeType.proceso) {
      setModoIntegracionId(3) // Calculado
    } else {
      setModoIntegracionId(indicador.modoIntegracionId || '')
    }
    // Obtener flujos disponibles
    axios.get(`${process.env.REACT_APP_MCU_URL}flujo/obtenerFlujos/`, {
      params: {
        public_key: process.env.REACT_APP_MCU_PUBLIC_KEY,
        tags: process.env.REACT_APP_INDICADOR_TAGS_MCU
      }
    })
      .then((response) => {
        const { data } = response
        if (response.status === 200) {
          setFlujosMCU(data?.content || [])
        } else {
          toast.error(response.message)
        }
      })
      .catch((error) => {
        toast.error(error.message)
      })
  }, [])

  function updateModosIntegracion() {
    getModosIntegracion().then((response) => {
      if (response.status === 200) {
        setModosIntegracion(response.body)
      } else {
        toast.error(response.message)
      }
    })
  }

  function updateModosIntegracionGlobal() {
    getModosIntegracionGlobal().then((response) => {
      if (response.status === 200) {
        setModosIntegracionGlobal(response.body)
      } else {
        toast.error(response.message)
      }
    })
  }

  function updateUnidadesMedida() {
    getUnidadesMedida().then((response) => {
      if (response.status === 200) {
        setUnidadesMedida(response.body)
      } else {
        toast.error(response.message)
      }
    })
  }

  function updateFrecuenciasIndicador() {
    getUnidadMedidaIndicador().then((response) => {
      if (response.status === 200) {
        setFrecuenciasIndicador(response.body)
      } else {
        toast.error(response.message)
      }
    })
  }

  function updateOperacionesIntegracion() {
    getOperacionesIntegracion().then((response) => {
      if (response.status === 200) {
        setOperacionesIntegracion(response.body)
      } else {
        toast.error(response.message)
      }
    })
  }

  const handleTieneMetasChange = (event) => {
    setTieneMetas(event.target.checked);
  };


  function checkForm() {
    if (habilitarSeries && series.length === 0) {
      toast.error('Debe agregar al menos una serie')
      setDisableButtons(false)
      return false
    }
    if (errorFormatoFecha) {
      toast.error('Formato de fecha inválido')
      setDisableButtons(false)
      return false
    }
    if (
      nombre.length > 0 &&
      unidadDeMedida.length > 0 &&
      fuenteDeDatos.length > 0 &&
      descripcion.length > 0 &&
      modoIntegracionId !== '' &&
      modoIntegracionGlobalId.length !== '' &&
      valorFrecuencia > 0 &&
      tipoMedicionPeriodo > 0 &&
      frecuenciaEvidencia > 0 &&
      unidadMedidaId !== ''
    ) {
      if (modoIntegracionId === INDICADOR_CALCULADO) {
        if (expresion.length <= 0) {
          toast.error(
            'Debe ingresar una expresión para los indicadores calculados'
          )
          setDisableButtons(false)
          return false
        }
      }
      return true
    } else {
      toast.error('Debe completar todos los campos')
      setDisableButtons(false)
      return false
    }
  }


  function saveAsNew() {
    save(true)
  }

  function validarDiferencias() {
    if (indicadorMetadata.id && indicador?.id) {
      if (nombre !== indicador.nombre) return true
      if (descripcion !== indicador.descripcion) return true
      if (unidadDeMedida !== indicador.unidadDeMedida) return true
      if (fuenteDeDatos !== indicador.fuenteDeDatos) return true
      if (parseFloat(metaConsolidada) !== parseFloat(indicador.metaConsolidada)) return true
      if (parseFloat(metaPorMedicion) !== parseFloat(indicador.metaPorMedicion)) return true
      if (parseFloat(metaIndividualPorMedicion) !== parseFloat(indicador.metaIndividualPorMedicion)) return true
      if (parseFloat(metaIndividualConsolidada) !== parseFloat(indicador.metaIndividualConsolidada)) return true
      if (modoIntegracionId !== indicador.modoIntegracionId) return true
      if (modoIntegracionGlobalId !== indicador.modoIntegracionGlobalId) return true
      if (unidadMedidaId !== indicador.unidadMedidaId) return true
      if (parseInt(valorFrecuencia) !== parseInt(indicador.valorFrecuencia)) return true
      if (parseInt(tipoMedicionPeriodo) !== parseInt(indicador.tipoMedicionPeriodo)) return true
      if (medicionIniciaLunes !== indicador.medicionIniciaLunes) return true
      if (operacionIntegracionId && operacionIntegracionId !== indicador.operacionIntegracionId) return true
      if (permiteSobregiro !== indicador.permiteSobregiro) return true
      if (limiteSobregiro !== indicador.limiteSobregiro) return true
      if (aplicaEvaluacionR !== indicador.aplicaEvaluacionR) return true
      if (aplicaEvaluacionA !== indicador.aplicaEvaluacionA) return true
      if (aplicaEvaluacionC !== indicador.aplicaEvaluacionC) return true
      if (aplicaEvaluacionI !== indicador.aplicaEvaluacionI) return true
      if (metaInversa !== indicador.metaInversa) return true
      if (habilitarSeries !== indicador.habilitarSeries) return true
      if (series.length && series.length !== indicador.series?.length) return true
      return false
    } else if (!indicadorMetadata.id) {
      return true
    }
    return false
  }

  function save(skipIdValidation = false) {
    setDisableButtons(true)
    if (!checkForm()) return
    const indicador = {
      nombre,
      descripcion,
      unidadDeMedida,
      fuenteDeDatos,
      metaConsolidada: tieneMetas ? metaConsolidada : 0,
      metaPorMedicion: tieneMetas ? metaPorMedicion : 0,
      metaIndividualPorMedicion: tieneMetas ? metaIndividualPorMedicion : 0,
      metaIndividualConsolidada: tieneMetas ? metaIndividualConsolidada : 0,
      activo: activo === 1 || activo === true ? true : false,
      modoIntegracionId:
        nodoActual.tipoNodoId === nodeType.actividad ? modoIntegracionId : 3, // Calculado
      modoIntegracionGlobalId,
      unidadMedidaId,
      Nodo_Indicador: nodoActual.id,
      valorFrecuencia,
      tipoMedicionPeriodo,
      frecuenciaEvidencia,
      medicionIniciaLunes,
      etiquetaEjeX,
      graficarEjeXDiscreto,
      formatoFecha,
      operacionIntegracionId:
        operacionIntegracionId > 0 ? operacionIntegracionId : null,
      IndicadorId: IndicadorId ? IndicadorId : null,
      publico: publico === 1 || publico === true ? true : false,
      permiteSobregiro:
        permiteSobregiro === 1 || permiteSobregiro === true ? true : false,
      limiteSobregiro: limiteSobregiro > 0 ? limiteSobregiro : null,
      formula: modoIntegracionId === INDICADOR_CALCULADO ? expresion : null,
      aplicaEvaluacionR:
        aplicaEvaluacionR === 1 || aplicaEvaluacionR === true ? true : false,
      aplicaEvaluacionA:
        aplicaEvaluacionA === 1 || aplicaEvaluacionA === true ? true : false,
      aplicaEvaluacionC:
        aplicaEvaluacionC === 1 || aplicaEvaluacionC === true ? true : false,
      aplicaEvaluacionI:
        aplicaEvaluacionI === 1 || aplicaEvaluacionI === true ? true : false,
      metaInversa: metaInversa === 1 || metaInversa === true ? true : false,
      habilitarSeries,
      lineasReferencia: lineasReferencia.map((linea) => ({ nombre: linea.nombre, valor: linea.valor, id: linea.id })),
      series,
      confMCU,
      tieneMetas
    }
    if (skipIdValidation) {
      return createIndicador(indicador)
    }

    if (indicadorMetadata.id) {
      indicador.id = indicadorMetadata.id
      updateIndicador(indicador)
    } else {
      createIndicador(indicador)
    }
  }

  const createIndicador = (indicador) => {
    postIndicador(indicador).then((response) => {
      setDisableButtons(false)
      if (response.status === 201) {
        toast.success('Indicador creado correctamente')
        toast.warning('Reiniciar el indicador para aplicar los cambios en el periodo seleccionado', { autoClose: false })
        setOpen(false)
        updateIndicadores()
        reloadTree()
      } else {
        toast.error(response.message)
      }
    })
  }

  const updateIndicador = (indicador) => {
    putIndicador(indicador).then((response) => {
      setDisableButtons(false)
      if (response.status === 200) {
        toast.success('Indicador modificado correctamente')
        if (validarDiferencias()) {
          toast.warning('Reiniciar el indicador para aplicar los cambios en el periodo seleccionado', { autoClose: false })
        }
        setOpen(false)
        updateIndicadores()
        reloadTree()
      } else {
        toast.error(response.message)
      }
    })
  }

  const eliminarSerie = (_index) => {
    let _series = series;
    _series = _series.filter((_, i) => i !== _index);
    setSeries(_series)
  }

  const handleFormatoFechaChange = (event) => {
    const inputValue = event.target.value;
    setFormatoFecha(inputValue);

    const regex = /^[DMYdmy\-/:. hs]+$/i;

    setErrorFormatoFecha(inputValue && !regex.test(inputValue));
  };

  return (
    <div style={{ maxHeight: '550px', overflow: 'auto' }}>
      <div style={{ display: 'flex', justifyContent: 'right', margin: '10px' }}>
        <AccordionOptions
          compressHandler={handleContraerTodos}
          expandHandler={handleExpandirTodos}
          expandTitle="Expandir todos"
          compressTitle="Contraer todos"
        />
      </div>
      <AccordionSection
        name={'Definiciones Generales'}
        description={'Define el nombre y descripción del indicador'}
        expanded={expanded.definicion}
        handleChange={() => handleChange('definicion')}
        id={'definicion'}
        children={
          <Grid container spacing={3} sx={{ marginTop: '0px' }}>
            <Grid item xs={6}>
              <TextField
                id="nombre"
                label="Nombre"
                variant="outlined"
                value={nombre}
                style={{ width: '100%' }}
                onChange={(e) => setNombre(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="fuenteDatos"
                label="Fuente de datos"
                variant="outlined"
                value={fuenteDeDatos}
                style={{ width: '100%' }}
                onChange={(e) => setFuenteDeDatos(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                id="descripcion"
                label="Descripción"
                variant="outlined"
                value={descripcion}
                multiline
                style={{ width: '100%' }}
                onChange={(e) => setDescripcion(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={3} sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <Tooltip title="Las series permiten ingresar múltiples valores para un mismo indicador" sx={{ mr: 1 }}>
                <InfoIcon fontSize='small' />
              </Tooltip>
              Habilitar series
              <Checkbox
                checked={habilitarSeries}
                onChange={(e) => {
                  if (esEdicion && habilitarSeriesOriginal !== e.target.checked) {
                    setCheckSeriesConfirm(e.target.checked);
                    setModalCheckSeriesConfirm(true);
                  } else {
                    setHabilitarSeries(e.target.checked);
                  }
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
            <Confirm
              open={modalCheckSeriesConfirm}
              title={"Confirmar elección"}
              text={"¿Confirma el cambio en la habilitación de series? Este cambio podría conllevar pérdida de datos."}
              onConfirm={() => {
                setHabilitarSeries(checkSeriesConfirm);
                setModalCheckSeriesConfirm(false);
                setCheckSeriesConfirm(null);
              }}
              handleCloseProp={() => {
                setModalCheckSeriesConfirm(false);
                setCheckSeriesConfirm(null);
              }}
            />
          </Grid>
        }
      />
      <AccordionSection
        name={'Medición'}
        description={
          'Define la unidad y frecuencia de medición y a que roles aplica'
        }
        expanded={expanded.medicion}
        handleChange={() => handleChange('medicion')}
        id={'medicion'}
        children={
          <Grid container spacing={3} sx={{ marginTop: '0px' }}>
            <Grid item xs={6}>
              <TextField
                id="unidadMedida"
                label="Unidad de Medida"
                variant="outlined"
                select
                value={unidadDeMedida}
                style={{ width: '100%' }}
                onChange={(e) => setUnidadDeMedida(e.target.value)}
                required
              >
                {unidadesMedida.map((unidad) => (
                  <MenuItem key={unidad.id} value={unidad.nombre}>
                    {unidad.nombre}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex' }}>
              <TextField
                id="valorFrecuencia"
                label="Frecuencia de medición"
                variant="outlined"
                value={valorFrecuencia}
                type={'number'}
                style={{ width: '100%' }}
                onChange={(e) => setValorFrecuencia(e.target.value)}
                required
              />
              <TextField
                id="frecuenciaIndicador"
                select
                label="Unidad de medida"
                value={unidadMedidaId}
                fullWidth
                onChange={(e) => setUnidadMedidaId(e.target.value)}
                required
              >
                {frecuenciasIndicador.map((frecuencia) => (
                  <MenuItem key={frecuencia.id} value={frecuencia.id}>
                    {frecuencia.nombre}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <Tooltip
                title={tituloAplicaR}
                enterDelay={500}
                arrow
                placement="right"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={aplicaEvaluacionR}
                      onChange={() => setAplicaEvaluacionR(!aplicaEvaluacionR)}
                    />
                  }
                  label="¿Aplica evaluación a responsable ejecutivo?"
                />
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <Tooltip
                title={tituloAplicaA}
                enterDelay={500}
                arrow
                placement="right"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={aplicaEvaluacionA}
                      onChange={() => setAplicaEvaluacionA(!aplicaEvaluacionA)}
                    />
                  }
                  label="¿Aplica evaluación a responsable directivo?"
                />
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <Tooltip
                title={tituloAplicaC}
                enterDelay={500}
                arrow
                placement="right"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={aplicaEvaluacionC}
                      onChange={() => setAplicaEvaluacionC(!aplicaEvaluacionC)}
                    />
                  }
                  label="¿Aplica evaluación a consultado?"
                />
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <Tooltip
                title={tituloAplicaI}
                enterDelay={500}
                arrow
                placement="right"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={aplicaEvaluacionI}
                      onChange={() => setAplicaEvaluacionI(!aplicaEvaluacionI)}
                    />
                  }
                  label="¿Aplica evaluación a informado?"
                />
              </Tooltip>
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex' }}>
              <FormControl>
                <FormLabel id="Tipo Periodo">Tipo de Periodo</FormLabel>
                <RadioGroup
                  row
                  value={tipoMedicionPeriodo}
                  onChange={(event) => {
                    setTipoMedicionPeriodo(event.target.value)
                  }}
                >
                  <FormControlLabel value={TIPOS_PERIODOS_INDICADOR.COMPLETO} control={<Radio />} label="Completo" />
                  <FormControlLabel value={TIPOS_PERIODOS_INDICADOR.SUB_PERIODO} control={<Radio />} label="Zafra" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6} sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <Tooltip title="Las fechas de medición se ajustarán al lunes más cercano
              a partir de la fecha de inicio del periodo completo o de zafra" sx={{ mr: 1 }}>
                <InfoIcon fontSize='small' />
              </Tooltip>
              ¿Medición inicia Lunes?
              <Checkbox
                checked={medicionIniciaLunes}
                onChange={(e) => setMedicionIniciaLunes(e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
          </Grid>
        }
      />
      {habilitarSeries && (
        <AccordionSection
          name={'Series asociadas'}
          description={
            'Define la cantidad de resultados que se ingresarán por indicador'
          }
          expanded={expanded.series}
          handleChange={() => handleChange('series')} holahola
          id={'series'}
          children={
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '10px'

              }}
            >
              <Confirm
                open={modalDeleteSerieConfirm}
                title={"Confirmar eliminación"}
                text={"¿Confirma que deseas eliminar esta serie? Este cambio podría conllevar pérdida de datos."}
                onConfirm={() => {
                  eliminarSerie(deleteSerieConfirm)
                  setModalDeleteSerieConfirm(false);
                  setDeleteSerieConfirm(null);
                }}
                handleCloseProp={() => {
                  setModalDeleteSerieConfirm(false);
                  setDeleteSerieConfirm(null);
                }}
              />
              {series.map((serie, index) => {
                return (
                  <SerieItem
                    key={index}
                    index={index}
                    name={serie.nombre}
                    deleteAction={(_index) => {
                      if (esEdicion && serie.id) {
                        setModalDeleteSerieConfirm(true);
                        setDeleteSerieConfirm(_index);
                      } else {
                        eliminarSerie(_index)
                      }
                    }}
                  // disabled={esEdicion}
                  >
                  </SerieItem>
                )
              })}
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Nombre de la serie"
                  variant="outlined"
                  style={{ width: '100%' }}
                  inputRef={serieRef}
                />
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: '#aebd36',
                    color: 'white',
                    marginLeft: '1rem'
                  }}
                  onClick={() => {
                    if (serieRef.current.value.length > 0) {
                      setSeries([...series, { nombre: serieRef.current.value }])
                      serieRef.current.value = ''
                    }
                  }
                  }
                >
                  Agregar
                </Button>
              </div>
            </div>
          }
        />
      )}
      <AccordionSection
        name={'Modos de integración'}
        description={'Define como se consolidan los resultados'}
        expanded={expanded.integracion}
        handleChange={() => handleChange('integracion')}
        id={'integracion'}
        children={
          <Grid container spacing={3} sx={{ marginTop: '0px' }}>
            <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                id="modoIntegracion"
                select
                label="Modo Integración"
                value={modoIntegracionId}
                fullWidth
                disabled={nodoActual.tipoNodoId === nodeType.proceso || esEdicion}
                onChange={(e) => setModoIntegracionId(e.target.value)}
                required
              >
                {modosIntegracion.map((modo) => (
                  <MenuItem key={modo.id} value={modo.id}>
                    {modo.nombre}
                  </MenuItem>
                ))}
              </TextField>
              <div
                style={{
                  width: '50px',
                  paddingLeft: '5px',
                  display:
                    modoIntegracionId === INDICADOR_CALCULADO ? 'block' : 'none'
                }}
              >
                <IconButton
                  aria-label="calculate"
                  onClick={() => setAbrirCalculadora(true)}
                  disabled={!getUnicoPeriodoSeleccionado()?.recalculoHabilitado}
                >
                  <CalculateIcon />
                </IconButton>
              </div>
            </Grid>
            {modoIntegracionId === 2 && (
              <Grid item xs={6} sx={{ display: 'flex' }}>
                <TextField
                  id="operacionIndicador"
                  select
                  label="Operación de integración"
                  value={operacionIntegracionId}
                  fullWidth
                  onChange={(e) => setOperacionIntegracionId(e.target.value)}
                  required
                >
                  {operacionesIntegracion.map((operacion) => (
                    <MenuItem key={operacion.id} value={operacion.id}>
                      {operacion.nombre}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
            <Grid item xs={6}>
              <TextField
                id="modoIntegracionGlobal"
                select
                label="Modo Integración global"
                value={modoIntegracionGlobalId}
                fullWidth
                onChange={(e) => setModoIntegracionGlobalId(e.target.value)}
                required
              >
                {modosIntegracionGlobal.map((modo) => (
                  <MenuItem key={modo.id} value={modo.id}>
                    {modo.nombre}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <FormControl>
                <FormLabel id="Frecuencia de Evidencias" style={{ display: 'flex' }}>
                  Frecuencia de Evidencias&nbsp;
                  <Tooltip title="Que evidencias se tomarán en cuenta para las mediciones" sx={{ mr: 1 }}>
                    <InfoIcon fontSize='small' sx={{ alignSelf: 'center' }} />
                  </Tooltip>
                </FormLabel>
                <RadioGroup
                  row
                  value={frecuenciaEvidencia}
                  onChange={(event) => {
                    setFrecuenciaEvidencia(parseInt(event.target.value))
                  }}
                >
                  {FRECUENCIAS_EVIDENCIA.map((frecuencia) => (
                    <FormControlLabel key={frecuencia.value} value={frecuencia.value} control={<Radio />} label={frecuencia.label} />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        }
      />
      <AccordionSection
        name={'Metas'}
        description={'Define las metas individuales y globales según aplique'}
        expanded={expanded.metas}
        handleChange={() => handleChange('metas')}
        id={'metas'}
        children={
          <Grid container spacing={3} sx={{ marginTop: '0px' }}>
            <Grid item xs={12}>
              <FormControlLabel control={
                <Switch checked={tieneMetas} onChange={handleTieneMetasChange} />
              } label="¿Indicador cuenta con metas?" />
            </Grid>
            {tieneMetas && (
              <React.Fragment>
                <Grid item xs={6}>
                  <TextField
                    id="metaPorMedicion"
                    label="Meta por medición"
                    variant="outlined"
                    value={metaPorMedicion}
                    style={{ width: '100%' }}
                    type={'number'}
                    onChange={(e) => setMetaPorMedicion(e.target.value)}
                    required
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    id="metaConsolidada"
                    label="Meta consolidada"
                    variant="outlined"
                    type={'number'}
                    value={metaConsolidada}
                    style={{ width: '100%' }}
                    onChange={(e) => setMetaConsolidada(e.target.value)}
                    required
                  />
                </Grid>
                {modoIntegracionId === 2 && (
                  <Grid item xs={6}>
                    <TextField
                      id="metaIndividualPorMedicion"
                      label="Meta individual por medición"
                      variant="outlined"
                      type={'number'}
                      value={metaIndividualPorMedicion}
                      style={{ width: '100%' }}
                      onChange={(e) => setMetaIndividualPorMedicion(e.target.value)}
                      required
                    />
                  </Grid>
                )}
                {modoIntegracionId === 2 && (
                  <Grid item xs={6}>
                    <TextField
                      id="metaIndividualConsolidada"
                      label="Meta individual consolidada"
                      variant="outlined"
                      type={'number'}
                      value={metaIndividualConsolidada}
                      style={{ width: '100%' }}
                      onChange={(e) => setMetaIndividualConsolidada(e.target.value)}
                      required
                    />
                  </Grid>
                )}

                <Grid item xs={6}>
                  <Tooltip
                    title={tituloMetaInversa}
                    enterDelay={500}
                    arrow
                    placement="right"
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={metaInversa}
                          onChange={() => setMetaInversa(!metaInversa)}
                        />
                      }
                      label="Meta inversa"
                    />
                  </Tooltip>
                </Grid>

                <Grid item xs={6} sx={{ display: 'flex' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'start',
                      marginTop: '0.5rem'
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={permiteSobregiro}
                          onChange={() => setPermiteSobregiro(!permiteSobregiro)}
                        />
                      }
                      label="¿Permite sobregiro?"
                    />
                  </div>
                </Grid>
                {permiteSobregiro && (
                  <Grid item xs={6}>
                    <TextField
                      id="limiteSobregiro"
                      label="Límite sobregiro"
                      variant="outlined"
                      type={'number'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        )
                      }}
                      value={limiteSobregiro}
                      style={{ width: '100%' }}
                      onChange={(e) => setLimiteSobregiro(e.target.value)}
                    />
                  </Grid>
                )}
              </React.Fragment>
            )}
          </Grid>
        }
      />
      <AccordionSection
        name={'Líneas de Referencia'}
        description={'Líneas de referencia para el indicador'}
        expanded={expanded.lineasReferencia}
        handleChange={() => handleChange('lineasReferencia')}
        id={'lineasReferencia'}
        children={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px'

            }}
          >
            {lineasReferencia.map((linea, index) => {
              return (
                <LineaItem
                  key={index}
                  index={index}
                  name={linea.nombre}
                  value={linea.valor}
                  deleteAction={(_index) => {
                    let _lineas = lineasReferencia;
                    _lineas = _lineas.filter((_, i) => i !== _index);
                    setLineasReferencia(_lineas)
                  }}
                />
              )
            })}
            <div
              style={{
                width: '100%',
                display: 'flex',
              }}
            >
              <TextField
                id="lineaReferencia"
                label="Nombre de linea de referencia"
                variant="outlined"
                style={{ width: '70%' }}
                inputRef={lineaNombreRef}
              />
              <TextField
                id="lineaReferenciaValor"
                type='number'
                label="Valor de linea de referencia"
                variant="outlined"
                style={{ width: '70%' }}
                inputRef={lineaValorRef}
              />
              <Button
                variant="contained"
                style={{
                  backgroundColor: '#aebd36',
                  color: 'white',
                  marginLeft: '1rem'
                }}
                onClick={() => {
                  if (lineaNombreRef.current.value.length && lineaValorRef.current.value.length) {
                    setLineasReferencia([...lineasReferencia,
                    { nombre: lineaNombreRef.current.value, valor: lineaValorRef.current.value }
                    ])
                    lineaNombreRef.current.value = ''
                    lineaValorRef.current.value = ''
                  }
                }
                }
              >
                Agregar
              </Button>
            </div>
          </div>
        }
      />
      <AccordionSection
        name={'Visibilidad'}
        description={'Define si el indicador es público o privado y la forma de graficar el eje x'}
        expanded={expanded.visibilidad}
        handleChange={() => handleChange('visibilidad')}
        id={'visibilidad'}
        children={
          <Grid container spacing={3} sx={{ marginTop: '0px' }}>
            <Grid item xs={6}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '0.5rem'
                }}
              >
                <Tooltip
                  title={tituloPublico}
                  enterDelay={500}
                  arrow
                  placement="right"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={publico}
                        onChange={() => setPublico(!publico)}
                      />
                    }
                    label="¿Público?"
                  />
                </Tooltip>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '0.5rem'
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={activo}
                      onChange={() => setActivo(!activo)}
                    />
                  }
                  label="¿Activo?"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '0.5rem'
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={etiquetaEjeX}
                      onChange={() => setEtiquetaEjeX(!etiquetaEjeX)}
                    />
                  }
                  label="Mostrar etiquetas en el eje horizontal"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '0.5rem'
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={graficarEjeXDiscreto}
                      onChange={() => setGraficarEjeXDiscreto(!graficarEjeXDiscreto)}
                    />
                  }
                  label="Gráficar eje x de forma discreta (recomendado para grandes volúmenes de datos)"
                />
              </div>
            </Grid>
            <Grid item container alignItems='center' xs={6}>
              <TextField
                id="formatoFecha"
                label="Formato de fecha en eje x"
                placeholder='Ejemplo: "yyy-MM-DD"'
                variant="outlined"
                value={formatoFecha || ''}
                style={{ width: '80%' }}
                error={errorFormatoFecha}
                helperText={errorFormatoFecha ? 'Formato de fecha inválido' : ''}
                onChange={handleFormatoFechaChange}
              />
              <IconButton color='info' title='Formatos de fechas válidos' onClick={e => setOpenPanelFormatos(!openPanelFormatos)}>
                <InfoIcon />
              </IconButton>
            </Grid>
            {(openPanelFormatos) && (
              <Grid item container alignItems='center' xs={12}>
                <TablaFormatos />
              </Grid>
            )}
          </Grid>
        }
      />
      <AccordionSection
        name={'Importación de datos (MCU)'}
        description={'Configuraciones de integración con el conector universal'}
        expanded={expanded.mcu}
        handleChange={() => handleChange('mcu')}
        id={'mcu'}
        children={
          <Grid container spacing={3} sx={{ marginTop: '0px' }}>
            {confMCU?.task && (
              <Grid item xs={12} alignContent={'center'} display={'flex'} alignItems={'center'}>
                <IconButton onClick={() => setOpenMCUDeleteTask(true)}>
                  <DeleteIcon color='error' />
                </IconButton>
                <small style={{ fontSize: "0.8em", fontWeight: 'bold' }}>
                  Para modificar esta configuración, debe eliminar la tarea programada
                </small>
                <Dialog
                  open={openMCUDeleteTask}
                  onClose={() => setOpenMCUDeleteTask(false)}
                >
                  <DialogTitle>
                    Confirmación de eliminación
                  </DialogTitle>
                  <DialogContent>
                    ¿Está seguro que desea eliminar la tarea programada?
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpenMCUDeleteTask(false)} color="primary">
                      Cancelar
                    </Button>
                    <Button
                      color="error"
                      variant='contained'
                      onClick={() => {
                        setLoading(true)
                        axios.delete(`${process.env.REACT_APP_MCU_URL}flujo/${confMCU?.task}/task/`, {
                          params: { public_key: process.env.REACT_APP_MCU_PUBLIC_KEY }
                        })
                          .then((response) => {
                            if (response.status === 200) {
                              const conf = { ...confMCU }
                              conf.task = null
                              setConfMCU(conf)
                              setOpenMCUDeleteTask(false)
                              putConfMCU({ confMCU: {}, indicadorId: indicador.id })
                              toast.success('Tarea eliminada correctamente')
                            } else {
                              toast.error(response.message)
                            }
                          })
                          .catch((error) => {
                            toast.error(error.message)
                          })
                          .finally(() => {
                            setLoading(false)
                          })
                      }}
                    >
                      Eliminar
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            )}
            <Grid item xs={6}>
              <TextField
                id="mcu-flows"
                select
                label="Flujos"
                value={confMCU?.flujo || null}
                fullWidth
                InputLabelProps={{ shrink: confMCU?.flujo }}
                InputProps={{ readOnly: confMCU?.task }}
                onChange={(e) => {
                  setConfMCU({
                    ...confMCU,
                    variables: [],
                    flujo: e.target.value
                  })
                }}
                required
              >
                {flujosMCU.map((flujo) => (
                  <MenuItem key={flujo.id} value={flujo.id}>
                    {flujo.nombre}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="mcu-cron"
                label="Frecuencia"
                value={confMCU?.cronText || null}
                fullWidth
                required
                InputLabelProps={{ shrink: confMCU?.cronText }}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <Tooltip
                      arrow
                      placement='top'
                      title={'Configurar frecuencia de ejecución'}
                    >
                      <IconButton
                        onClick={() => {
                          if (confMCU?.task) return
                          setOpenMCUCron(!openMCUCron)
                        }}
                      >
                        <ScheduleIcon />
                      </IconButton>
                    </Tooltip>
                  )
                }}
              />
              <Dialog
                open={openMCUCron}
                onClose={() => setOpenMCUCron(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle>
                  Seleccione la frecuencia&nbsp;
                  <small style={{ fontSize: "0.6em" }}>
                    (Zona horaria UTC)
                  </small>
                </DialogTitle>
                <DialogContent>
                  <Cron
                    onChange={(cron, text) => {
                      setConfMCU({
                        ...confMCU,
                        cron,
                        cronText: text
                      })
                    }}
                    locale="es"
                    value={confMCU?.cron}
                    showResultText={true}
                    showResultCron={false}
                    translateFn={(value) => {
                      return cronTranslation[value]
                    }}
                    options={{
                      headers: [
                        HEADER.MONTHLY, HEADER.WEEKLY,
                        HEADER.MINUTES, HEADER.HOURLY,
                        HEADER.DAILY, // HEADER.CUSTOM
                      ]
                    }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOpenMCUCron(false)} color="primary">
                    Cerrar
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
            {/* Ejecución manual */}
            {confMCU?.flujo && (
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setConfirmFlowExecutionDialog(true)
                  }}
                  startIcon={<PlayArrowIcon />}
                >
                  Ejecutar
                </Button>
                <Confirm
                  open={confirmFlowExecutionDialog}
                  title={"Confirmar ejecución"}
                  text={`¿Está seguro que desea ejecutar "${flujosMCU.find(flujo => flujo.id === confMCU?.flujo)?.nombre}"?`}
                  onConfirm={() => {
                    setLoading(true)
                    ejecutarFlujo({ ...confMCU, indicadorId: indicador.id })
                    .then((response) => {
                      const detail = response?.body?.body?.detail
                      if (response.status === 201 && detail && detail == 'success') {
                        toast.success('Flujo ejecutado correctamente')
                      } else {
                        const error_logs = response?.body?.body?.content?.log?.filter(log => log.STATUS === 'ERROR') || []
                        const message = error_logs.reduce((acc, log) => `${acc}\n${log.MESSAGE}`, '')
                        toast.error(message || 'Error al ejecutar el flujo', { autoClose: false })
                      }
                    })
                    .catch((error) => {
                      toast.error(error.message)
                    })
                    .finally(() => {
                      setLoading(false)
                      setConfirmFlowExecutionDialog(false)
                    })

                  }}
                  handleCloseProp={() => {
                    setConfirmFlowExecutionDialog(false)
                  }}
                />
              </Grid>
            )}
            {flujosMCU?.find(flujo => flujo.id === confMCU?.flujo)?.variables?.filter(variable => variable !== 'indicadorId')?.length > 0 && (
              <>
                <Grid item xs={12}>
                  <h4>Variable(s)</h4>
                </Grid>
                {flujosMCU?.find(flujo => flujo.id === confMCU?.flujo)?.variables?.filter(variable => variable !== 'indicadorId')?.map((variable, index) => {
                  return (
                    <Grid item xs={6} key={index}>
                      <TextField
                        id={`mcu-variable-${variable}`}
                        label={variable}
                        value={confMCU?.variables?.find(varia => varia.nombre == variable)?.valor || ''}
                        fullWidth
                        InputProps={{ readOnly: confMCU?.task }}
                        onChange={(e) => {
                          const newVariables = [...confMCU?.variables || []]
                          const item = newVariables.find(item => item.nombre === variable)
                          if (item) {
                            item.valor = e.target.value
                          } else {
                            newVariables.push({ nombre: variable, valor: e.target.value })
                          }
                          setConfMCU({
                            ...confMCU,
                            variables: newVariables
                          })
                        }}
                      />
                    </Grid>
                  )
                })}
              </>
            )}
          </Grid>
        }
      />
      {validarDiferencias() && (
        <Alert severity="warning">
          <AlertTitle>Reiniciar periodo</AlertTitle>
          Para que los cambios que está a punto de guardar tomen efecto necesita
          reiniciar el periodo para este indicador.
        </Alert>
      )}
      <div style={{ marginTop: '1rem', float: 'right' }}>
        {indicador.id && !habilitarSeries && (
          <Button
            variant="contained"
            color="warning"
            style={{ color: 'white', marginLeft: '1rem' }}
            onClick={saveAsNew}
          >
            Crear una copia
          </Button>
        )}
        <LoadingButton
          loading={disableButtons}
          disabled={disableButtons}
          onClick={() => save()}
          color='primary'
          variant='contained'
        >
          <span>Guardar</span>
        </LoadingButton>
      </div>

      <ModalCalculadora
        open={abrirCalculadora}
        setOpen={setAbrirCalculadora}
        expresion={expresion}
        setExpresion={setExpresion}
      />
    </div>
  )
}

export { IndicadoresForm }

import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import { postNodoIA, saveNodoIA } from "../Api/nodos.api";
import { toast } from "react-toastify";
import { NodoContext } from "../Context/nodoContext";
import TreeView from "../Custom/TreeView";

export default function SuggestNodeForm({
    nodeParentId = null,
    closeModalFn
}) {
    const [context, setContext] = useState("");
    const [responseIA, setResponseIA] = useState(null);
    const [nodeLevel, setNodeLevel] = useState(null);

    const { setLoading, setLoadingMessage, reloadTree } = React.useContext(NodoContext);

    const handleChangeContext = (event) => {
        setContext(event.target.value);
    }

    const save = async () => {
        try {
            setLoading(true);
            setLoadingMessage("Guardando nodos...");
            const response = await saveNodoIA({
                structure: responseIA,
                nodeParentId,
                node_level: nodeLevel
            });
            const { status } = response;
            if (status === 201) {
                setResponseIA(null);
                if (closeModalFn) {
                    closeModalFn();
                }
                toast.success("Nodos guardados correctamente");
                try {
                    reloadTree();
                } catch (error) {}
            } else {
                toast.error("Error al guardar los nodos");
            }
        } catch (error) {
            toast.error(`Error al guardar los nodos ${error}`);
        } finally {
            setLoading(false);
            setLoadingMessage("");
        }
    }

    const generate = async () => {
        try {
            setLoading(true);
            setLoadingMessage("Generando sugerencia de nodo...");
            const response = await postNodoIA({
                context,
                nodeParentId
            });
            const { status } = response;
            const { message, node_level } = response?.body?.body || {};
            if (status === 201) {
                setResponseIA(message);
                setNodeLevel(node_level);
            } else {
                toast.error("Error al generar sugerencia de nodo");
            }
        } catch (error) {
            toast.error("Error al generar sugerencia de nodo");
        } finally {
            setLoading(false);
            setLoadingMessage("");
        }
    }

    return (
        <React.Fragment>
            <Grid container spacing={3} marginTop={'1px'}>
                <Grid item xs={12} lg={12} sm={12}>
                    {/* Contexto */}
                    {!responseIA ? (
                        <TextField
                            id="context"
                            label="Contexto adicional"
                            variant="outlined"
                            multiline
                            rows={4}
                            value={context}
                            style={{ width: "100%" }}
                            onChange={handleChangeContext}
                        />
                    ) : (
                        <div style={{ overflow: 'auto', maxHeight: '60vh' }}>
                            {/* <div style={{ whiteSpace: 'pre-line' }}>
                                {responseIA}
                            </div> */}
                            <TreeView data={responseIA} />
                        </div>
                    )}
                </Grid>
                <Grid item xs={12} lg={12} sm={12} justifyContent={'right'} display={'flex'}>
                    {responseIA && (
                        <Button
                            variant="outlined"
                            onClick={() => setResponseIA(null)}
                            startIcon={<ArrowBackIcon />}
                            style={{ marginRight: '10px' }}
                        >
                            Contexto
                        </Button>
                    )}
                    {!responseIA && (
                        <Button
                            variant="contained"
                            onClick={() => generate()}
                            startIcon={<AutoAwesomeIcon />}
                        >
                            Generar
                        </Button>
                    )}
                    {responseIA && (
                        <Button
                            variant="contained"
                            onClick={() => save()}
                            startIcon={<SaveIcon />}
                            style={{ marginRight: '10px' }}
                        >
                            Guardar
                        </Button>
                    )}
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify'
import { Box, Grid, Card, CardContent, TextField, IconButton, CardActions, Button } from "@mui/material";
import { NodoContext } from '../../Context/nodoContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { PeriodoContext } from '../../Context/periodoContext';
import { MessageBar } from '../../general-components/messageBar';
import { getFODA, saveFODA } from '../../Api/estrategia.api';
import RemoveIcon from '@mui/icons-material/Cancel';

const categories = {
    strengths: {
        title: 'Fortalezas',
        headerColor: '#00b050'
    },
    opportunities: {
        title: 'Oportunidades',
        headerColor: '#e46c0a'
    },
    weaknesses: {
        title: 'Debilidades',
        headerColor: '#8064a2'
    },
    threats: {
        title: 'Amenazas',
        headerColor: '#ffc000'
    }
}

const FODA = () => {

    const location = useLocation()
    const navigate = useNavigate();

    const { setLoading, setLoadingMessage } = React.useContext(NodoContext)
    const { getUnicoPeriodoSeleccionado } = React.useContext(PeriodoContext);

    const [periodoId, setPeriodoId] = useState(null)

    const [foda, setFoda] = React.useState({})
    const [newFoda, setNewFoda] = React.useState({})
    const [categotyEditing, setCategoryEditing] = React.useState(Object.keys(categories).reduce((acc, category) => {
        acc[category] = false
        return acc
    }, {}))

    useEffect(() => {
        const _periodoId = getUnicoPeriodoSeleccionado()?.id
        if (_periodoId !== periodoId) {
            setPeriodoId(_periodoId)
        }
    }, [getUnicoPeriodoSeleccionado()])

    useEffect(() => {
        if (periodoId) {
            setLoading(true)
            setLoadingMessage('Cargando información...')
            getFODA(periodoId)
                .then((response) => {
                    setFoda(response.body || {})
                    setNewFoda(response.body || {})
                    setLoading(false)
                })
                .catch((error) => {
                    toast.error('Error al cargar la información')
                    setLoading(false)
                })
        }
    }, [periodoId])

    const SaveFODA = (value, field) => {
        setLoading(true)
        setLoadingMessage('Guardando información...')
        saveFODA({
            [field]: value,
        }, periodoId)
            .then(() => {
                toast.success('Información guardada correctamente')
                setLoading(false)
                setFoda({ ...foda, [field]: value })
                setNewFoda({ ...newFoda, [field]: value })
                setCategoryEditing({ ...categotyEditing, [field]: false })
            })
            .catch(() => {
                toast.error('Error al guardar la información')
                setLoading(false)
            })
    }

    if (getUnicoPeriodoSeleccionado() === null)
        return (
            <MessageBar
                display={true}
                type="error"
                message="Debe de seleccionar un único periodo para visualizar los resultados"
                title="Error"
            />
        )

    return (
        <Grid container xs={12} spacing={2} sx={{ padding: '2em' }} alignItems={'center'}>
            {Object.keys(categories).map((category, index) => {
                return (
                    <Grid item xs={12} md={6}>
                        <Card
                            onDoubleClick={() => {
                                setCategoryEditing({ ...categotyEditing, [category]: true })
                            }}
                            sx={{
                                border: 4,
                                borderColor: categories[category]?.headerColor,
                                borderRadius: 5
                            }}
                        >
                            <CardContent sx={{ background: categories[category]?.headerColor }}>
                                <Box display="flex" justifyContent={(index + 1) % 2 == 0 ? 'right' : 'left'}>
                                    <h1>
                                        {categories[category]?.title}
                                    </h1>
                                </Box>
                            </CardContent>
                            <CardContent>
                                <Box display="flex" justifyContent="left" alignItems="left">
                                    {!categotyEditing[category] ? (
                                        <p style={{ textAlign: 'left' }}>
                                            {foda[category]?.map((value, index) => (
                                                <li key={index}>{value}</li>
                                            ))}
                                        </p>
                                    ) : (
                                        <Grid container>
                                            {newFoda[category]?.map((value, index) => (
                                                <Grid item xs={12} key={index} sx={{ paddingY: 1 }}>
                                                    <TextField
                                                        fullWidth
                                                        autoFocus
                                                        value={value}
                                                        onChange={(e) => {
                                                            const newFodaCopy = { ...newFoda }
                                                            newFodaCopy[category][index] = e.target.value
                                                            setNewFoda(newFodaCopy)
                                                        }}
                                                        onKeyUp={(e) => {
                                                            if (e.key === 'Enter') {
                                                                const newFodaCopy = { ...newFoda }
                                                                newFodaCopy[category]?.push('') || (newFodaCopy[category] = [''])
                                                                setNewFoda(newFodaCopy)
                                                            }
                                                        }}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <IconButton
                                                                    onClick={() => {
                                                                        const newFodaCopy = { ...newFoda }
                                                                        newFodaCopy[category]?.splice(index, 1)
                                                                        setNewFoda(newFodaCopy)
                                                                    }}
                                                                >
                                                                    <RemoveIcon />
                                                                </IconButton>
                                                            )
                                                        }}
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    )}
                                </Box>
                            </CardContent>
                            {/* Añadir nuevo valor a la lista */}
                            {categotyEditing[category] && (
                                <CardActions sx={{ justifyContent: 'right' }}>
                                    <Button
                                        onClick={() => {
                                            const newFodaCopy = { ...newFoda }
                                            newFodaCopy[category]?.push('') || (newFodaCopy[category] = [''])
                                            setNewFoda(newFodaCopy)
                                        }}
                                    >
                                        Añadir valor
                                    </Button>
                                </CardActions>
                            )}
                            <CardActions sx={{ justifyContent: 'right' }}>
                                {categotyEditing[category] && (
                                    <>
                                        <Button
                                            onClick={() => {
                                                setCategoryEditing(Object.keys(categories).reduce((acc, category) => {
                                                    acc[category] = false
                                                    return acc
                                                }, {}));
                                                setNewFoda(foda)
                                            }}
                                        >
                                            Cancelar
                                        </Button>
                                        <Button
                                            variant='contained'
                                            onClick={() => {
                                                SaveFODA(newFoda[category].filter(newValue => newValue != ""), category)
                                            }}
                                        >
                                            Guardar
                                        </Button>
                                    </>
                                )}
                            </CardActions>
                        </Card>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default FODA;

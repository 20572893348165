import React from 'react';
import { buildTree } from '../Utils/utilities';

const TreeNode = ({ node }) => {
    const indentStyle = {
        marginLeft: `${(node.tipoNodoId - 1) * 20}px`
    };

    return (
        <li style={indentStyle}>
            <div>
                <strong>{node.nombre}</strong> ({node.codigo}): {node.descripcion}
            </div>
            {node.children.length > 0 && (
                <ul>
                    {node.children.map(child => (
                        <TreeNode key={child.codigo} node={child} />
                    ))}
                </ul>
            )}
        </li>
    );
};

const TreeView = ({ data }) => {
    const treeData = buildTree(data);

    return (
        <ul>
            {treeData.map(node => (
                <TreeNode key={node.codigo} node={node} />
            ))}
        </ul>
    );
};

export default TreeView;
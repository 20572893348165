import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify'
import { Box, Fab, Grid, TextField, IconButton } from "@mui/material";
import { NodoContext } from '../../Context/nodoContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { PeriodoContext } from '../../Context/periodoContext';
import { MessageBar } from '../../general-components/messageBar';
import { getPESTEL, savePESTEL } from '../../Api/estrategia.api';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';

const AREA = {
    political: 'Politico',
    economic: 'Economico',
    social: 'Social',
    technological: 'Tecnologico',
    environmental: 'Ambiental',
    legal: 'Legal',
    geographical: 'Geográfico',
    sweeteners_competitiveness: 'Competitividad Edulcorantes',
    energy_competitiveness: 'Competitividad Energía',
}

const CATEGORIES = {
    factors: 'Factor o Condición',
    scopes: 'Alcance',
    impacts: 'Efecto',
}

const PESTEL = () => {

    const location = useLocation()
    const navigate = useNavigate();

    const { setLoading, setLoadingMessage } = React.useContext(NodoContext)
    const { getUnicoPeriodoSeleccionado } = React.useContext(PeriodoContext);

    const [periodoId, setPeriodoId] = useState(null);

    const [pestel, setPestel] = React.useState({})
    const [newPestel, setNewPestel] = React.useState({})
    const [areaEditing, setAreaEditing] = React.useState(Object.keys(AREA).reduce((acc, key) => {
        acc[key] = false
        return acc
    }, {}))

    useEffect(() => {
        const _periodoId = getUnicoPeriodoSeleccionado()?.id
        if (_periodoId !== periodoId) {
            setPeriodoId(_periodoId)
        }
    }, [getUnicoPeriodoSeleccionado()])

    useEffect(() => {
        if (periodoId) {
            setLoading(true)
            setLoadingMessage('Cargando información...')
            getPESTEL(periodoId)
                .then((response) => {
                    setPestel(response?.body || {})
                    setNewPestel(response?.body || {})
                    setLoading(false)
                })
                .catch((error) => {
                    toast.error('Error al cargar la información')
                    setLoading(false)
                })
        }
    }, [periodoId])

    if (getUnicoPeriodoSeleccionado() === null)
        return (
            <MessageBar
                display={true}
                type="error"
                message="Debe de seleccionar un único periodo para visualizar los resultados"
                title="Error"
            />
        )

    return (
        <Grid container xs={12} >
            {/* Área */}
            <Grid container xs={12} sx={{ borderBottom: 1, borderColor: 'divider', paddingY: 1 }} alignItems={'center'}>
                <Grid item xs>
                    <Box sx={{ fontWeight: 'bold' }}>
                        Área
                    </Box>
                </Grid>
                {Object.keys(CATEGORIES).map((key) => {
                    return (
                        <Grid item xs>
                            <Box sx={{ fontWeight: 'bold' }}>
                                {CATEGORIES[key]}
                            </Box>
                        </Grid>
                    )
                })}
            </Grid>
            {Object.keys(AREA).map((key) => {
                return (
                    <Grid container xs={12} alignItems={'center'} sx={{ borderBottom: 1, borderColor: 'divider', paddingY: 1 }}>
                        <Grid item xs>
                            <Box sx={{ textAlign: 'left', cursor: 'pointer', paddingX: 2, fontWeight: 'bold' }}
                                onDoubleClick={() => {
                                    setAreaEditing({
                                        ...areaEditing,
                                        [key]: true
                                    })
                                }}
                            >
                                {AREA[key]}
                            </Box>
                        </Grid>
                        {Object.keys(CATEGORIES).map((category) => {
                            return (
                                <Grid item xs>
                                    <Box sx={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                        {!areaEditing[key] ?
                                            <p style={{ textAlign: 'left' }}>
                                                {pestel[key]?.[category]?.map((item) => {
                                                    return (
                                                        <li>
                                                            {item}
                                                        </li>
                                                    )
                                                })}
                                            </p> :
                                            <>
                                                {newPestel[key]?.[category]?.map((item, index) => {
                                                    return (
                                                        <TextField
                                                            key={index}
                                                            value={item}
                                                            fullWidth
                                                            multiline
                                                            maxRows={4}
                                                            autoFocus
                                                            onChange={(e) => {
                                                                setNewPestel({
                                                                    ...newPestel,
                                                                    [key]: {
                                                                        ...newPestel[key],
                                                                        [category]: newPestel[key][category].map((item, i) => {
                                                                            if (i === index) {
                                                                                return e.target.value
                                                                            }
                                                                            return item
                                                                        })
                                                                    }
                                                                })
                                                            }}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            setNewPestel({
                                                                                ...newPestel,
                                                                                [key]: {
                                                                                    ...newPestel[key],
                                                                                    [category]: newPestel[key][category].filter((item, i) => i !== index)
                                                                                }
                                                                            })
                                                                        }}
                                                                    >
                                                                        <RemoveIcon />
                                                                    </IconButton>
                                                                )
                                                            }}
                                                        />
                                                    )
                                                })}
                                                <IconButton
                                                    onClick={() => {
                                                        setNewPestel({
                                                            ...newPestel,
                                                            [key]: {
                                                                ...newPestel[key],
                                                                [category]: newPestel[key]?.[category]?.concat('') || ['']
                                                            }
                                                        })
                                                    }}
                                                >
                                                    <AddIcon />
                                                </IconButton>
                                            </>
                                        }
                                    </Box>
                                </Grid>
                            )
                        })}
                    </Grid>
                )
            })}
            {/* FABS GUARDAR Y CANCELAR */}
            {Object.keys(areaEditing).some((key) => areaEditing[key]) &&
                <>
                    <Fab
                        color="secondary"
                        variant="extended"
                        sx={{ position: 'fixed', bottom: 20, right: 100 }}
                        onClick={() => {
                            setAreaEditing(Object.keys(areaEditing).reduce((acc, key) => {
                                acc[key] = false
                                return acc
                            }, {}))
                            setNewPestel(pestel)
                        }}
                    >
                        <RemoveIcon />
                    </Fab>
                    <Fab
                        color="primary"
                        variant="extended"
                        sx={{ position: 'fixed', bottom: 20, right: 20 }}
                        onClick={() => {
                            setLoading(true)
                            setLoadingMessage('Guardando Cambios')
                            savePESTEL(newPestel, periodoId).then(() => {
                                setAreaEditing(Object.keys(areaEditing).reduce((acc, key) => {
                                    acc[key] = false
                                    return acc
                                }, {}))
                                setPestel(newPestel)
                                setLoading(false)
                                toast.success('Cambios guardados correctamente')
                            }).catch(() => {
                                setLoading(false)
                                toast.error('Error al guardar los cambios')
                            })
                        }}
                    >
                        <SaveIcon />
                    </Fab>
                </>
            }
        </Grid>
    )
}

export default PESTEL;

import React, { useEffect } from 'react'
import { Typography } from '@mui/material'
import { DataGrid } from 'devextreme-react'
import {
  Column,
  FilterPanel,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  Selection
} from 'devextreme-react/data-grid'
import themes from 'devextreme/ui/themes'
import { useDownloadExcel } from '../Hooks/useDownloadExcel'
import { Export } from 'devextreme-react/chart'


function ChartTable({ dataArray, periodos, title, etiquetaEjeX }) {
  const [data, setData] = React.useState([])

  useEffect(() => {
    let dataTemp = [...data]
    if (Array.isArray(periodos)) {
      const periodoEvaluacion = periodos.filter(
        (i) => !i.name.startsWith('Meta')
      )
      periodoEvaluacion.map((periodo) => {
        if (Array.isArray(dataArray)) {
          if (dataArray.length < 1) {
            return
          }
          let keys = Object.keys(dataArray[0])

          let dataPart = dataArray.filter(
            (item) =>
              item[periodo.name] !== undefined /*&& item[periodo.name]!==null*/
          )

          const newData = dataPart.map((e) => {
            return {
              periodo: periodo.name,
              fecha: e.fecha,
              serie: e.serie,
              resultado: e[periodo.name],
              meta: e['Meta ' + periodo.name]
            }
          })

          dataTemp = [...dataTemp, ...newData]

          setData(dataTemp)
        }
      })
    }
  }, [dataArray])

  return (
    <>
      <p></p>

      <div style={{ display: 'flex', width: '100%' }}>
        <section
          style={{
            padding: '15px',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Typography variant="h5" gutterBottom>
            {`${title}`}
          </Typography>
          <div
            style={{
              // height: "calc(100vh - 325px)",
              overflow: 'scroll',
              width: '100%',
              maxWidth: '100%'
            }}
          >
            <ChartDataGrid propRef={null} dataSource={data} etiquetaEjeX={etiquetaEjeX} />
          </div>
        </section>
      </div>
    </>
  )
}

function ChartDataGrid({ propRef, dataSource, etiquetaEjeX }) {
  const { onExporting } = useDownloadExcel()
  return (
    <DataGrid
      ref={propRef}
      dataSource={dataSource}
      allowColumnReordering={true}
      allowColumnResizing={true}
      columnResizingMode="nextColumn"
      onExporting={onExporting}
      // defaultColumns={columns}
      // rowRender={plantillaFila}
    >
      <Selection
        //mode="multiple"
        //selectAllMode={"allMode"}
        showCheckBoxesMode={
          themes.current().startsWith('material') ? 'always' : 'onClick'
        }
      />
      <Column
        dataField="periodo"
        caption="Período"
        dataType="string"
        visible={true}
      />
      <Column
        dataField="fecha"
        caption="Fecha"
        dataType="date"
        format={'dd/MM/yyyy HH:mm'}
        visible={true}
      />
      {(etiquetaEjeX) && (
        <Column
          dataField="serie"
          caption="Etiqueta"
          dataType="string"
          visible={true}
        />
      )}
      <Column
        dataField="resultado"
        caption="Resultado"
        dataType="number"
        visible={true}
      />
      <Column
        dataField="meta"
        caption="Meta"
        dataType="number"
        visible={true}
      />

      <FilterRow visible={true} />
      <FilterPanel visible={false} />
      {/* <FilterBuilderPopup position={filterBuilderPopupPosition} /> */}
      <HeaderFilter visible={true} />
      {/* <Scrolling mode="infinite" /> */}

      {/* <GroupPanel visible={true} /> */}
      {/* <SearchPanel visible={true} highlightCaseSensitive={true} /> */}
      {/* <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} /> */}
      <Paging defaultPageSize={50} />
      <Pager
        showPageSizeSelector={true}
        allowedPageSizes={[50, 100, 150, 200]}
        showInfo={true}
      />
      <Export enabled={true} allowExportSelectedData={true} />
    </DataGrid>
  )
}

export { ChartTable }

import React, { useState, useEffect } from "react";
import Modal from "../general-components/modal";
import { Autocomplete, Button, Checkbox, FormControlLabel, Grid, MenuItem, TextField } from "@mui/material";
import { OPERACIONES_AGG, TARGET_RULES } from "../Utils/graphConstants";
import { getMetadataDataset } from "../Api/vistaDatasets";

export default function TotalKPIModal({ open, closeFn, indicador, dataset, handleSaveTotalKPI }) {
  const [nombre, setNombre] = useState("");
  const [operacion, setOperacion] = useState(null);
  const [conMeta, setConMeta] = useState(true);
  const [campos, setCampos] = React.useState([])
  const [campoValue, setCampoValue] = React.useState(null)
  const [campoTarget, setCampoTarget] = React.useState(null)
  const [reglaTarget, setReglaTarget] = React.useState(null)

  console.log('TotalKPIModal indicador ', indicador);

  useEffect(() => {
    if (indicador) {
      console.log("indicador ", indicador);
      if (indicador.configGrafica) {
        if (indicador.porDataset) {
          setCampoValue(indicador.configGrafica?.resumen?.campos_value[0]);
          setCampoTarget(indicador.configGrafica?.resumen?.campos_target[0]);
          setOperacion(indicador.configGrafica?.resumen?.operacionesAgg[0]);
          setReglaTarget(indicador.configGrafica?.resumen?.regla_target);
          setNombre(indicador.configGrafica?.resumen?.nombre || indicador.indicadorNombre);
        } else {
          setOperacion(indicador.configGrafica.operacion);
          setConMeta(indicador.configGrafica.con_meta);
          setNombre(indicador.configGrafica.nombre || indicador.indicadorNombre);
        }
      } else {
        setNombre(indicador.indicadorNombre);
        setOperacion(null);
        setConMeta(true);
        setCampoValue(null);
        setCampoTarget(null);
        setReglaTarget(null);
      }
    }
  }, [indicador])

  useEffect(() => {
    if (dataset) {
      getMetadata()
    }
  }, [dataset])

  const getMetadata = async () => {
    try {
      const response = await getMetadataDataset(dataset)
      console.log('response ', response)
      if (response.status === 200 || response.status === 201) setCampos(response.body);
    } catch (error) {
      console.error(error)
    }
  }

  const handleSave = () => {
    const data = { nombre }
    if (indicador.porDataset) {
      data.campos_value = campoValue ? [campoValue] : []
      data.campos_target = campoTarget ? [campoTarget] : []
      data.operacionesAgg = operacion ? [operacion] : []
      data.regla_target = reglaTarget
      data.con_meta = !!campoTarget
    } else {
      data.operacion = operacion
      data.con_meta = conMeta
    }
    handleSaveTotalKPI(data, indicador);
  }

  return (
    <Modal title="Configuración KPI" open={open} handleClose={closeFn}>
      <Grid container spacing={2} sx={{ marginTop: '1rem' }}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Título"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
          />
        </Grid>
        {(indicador && indicador.porDataset) ? (
          <React.Fragment>
            <Grid item xs={12} md={6}>
              <Autocomplete
                options={campos}
                value={campoValue}
                getOptionLabel={(option) => `${option.columna} (${option.tipo})`}
                onChange={(event, newValue) => setCampoValue(newValue)}
                renderInput={(params) => <TextField {...params} label="Campo Valor" variant='outlined' />}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                options={campos}
                value={campoTarget}
                getOptionLabel={(option) => `${option.columna} (${option.tipo})`}
                onChange={(event, newValue) => setCampoTarget(newValue)}
                renderInput={(params) => <TextField {...params} label="Campo Meta" variant='outlined' />}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                value={operacion}
                onChange={(event) => setOperacion(event.target.value)}
                placeholder="Operación"
                label="Operación"
                fullWidth
                // sx={{ marginLeft: '1rem', minWidth: '150px' }}
              >
                {OPERACIONES_AGG.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                value={reglaTarget}
                onChange={(event) => setReglaTarget(event.target.value)}
                placeholder="Regla de comparación"
                label="Regla de comparación"
                fullWidth
              >
                {TARGET_RULES.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6} />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Grid item xs={12} md={6}>
              <TextField
                select
                value={operacion}
                onChange={(event) => setOperacion(event.target.value)}
                placeholder="Operación"
                fullWidth
                sx={{ marginLeft: '1rem', minWidth: '150px' }}
              >
                {OPERACIONES_AGG.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={conMeta}
                    onChange={() => setConMeta(!conMeta)}
                  />
                }
                label="Comparar con meta"
              />
            </Grid>
          </React.Fragment>
        )}
        <div style={{ marginTop: '3rem', marginBottom: '1rem' }}>
          <Button
            variant="contained"
            color='inherit'
            style={{ marginRight: '1rem' }}
            onClick={closeFn}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleSave}
            color='primary'
            variant='contained'
          >
            <span>Guardar</span>
          </Button>
        </div>
      </Grid>
    </Modal>
  )
}
import { GRAFICAS_BASICAS } from "../../Utils/graphConstants";

const validarBasicas = (config) => {
  const errores = {}
  if (config.camposEjeX.length > 1) {
    errores.camposEjeX = 'Solo se permite un campo en el eje X';
  } else if (config.camposGroupby.length > 1) {
    errores.camposGroupby = 'Solo se permite un campo en el groupby';
  } else if (config.camposGroupby.length === 1 && config.camposEjeY.length > 1) {
    errores.camposEjeY = 'No se permite mas de un campo en el eje Y si se esta agrupando por un campo';
    errores.camposGroupby = 'No se permite mas de un campo en el groupby si se estan graficando varios campos en el eje Y';
  } else if (config.operacionesAgg.filter(item => !!item).length !== config.camposEjeY.length) {
    errores.operacionesAgg = 'El numero de operaciones debe ser igual al numero de campos en el eje Y';
  } else if (config.sortedBy && config.sortedBy.columna) {
    const campos1 = config.camposEjeX.concat(config.camposEjeY).map(item => item.columna);
    const campos2 = config.camposEjeX.concat(config.camposGroupby).map(item => item.columna);
    if (!config.camposGroupby.length && !campos1.includes(config.sortedBy.columna)) {
      errores.sortedBy = 'El campo seleccionado para ordenar no se encuentra en los campos a graficar';
    } else if (config.camposGroupby.length && !campos2.includes(config.sortedBy.columna)) {
      errores.sortedBy = 'El campo seleccionado para ordenar no se encuentra en los campos a agrupar';
    }
  }
  return errores;
}

const validarConfig = (config) => {
  if (GRAFICAS_BASICAS.includes(config.tipoGrafica)) {
    return validarBasicas(config);
  } else {
    return {};
  }
}

export {
  validarConfig
}

import { HOST } from "../Utils/config";
import { post } from "../Utils/api";
import { getRequest, postRequest, putRequest, deleteRequest } from "./requests";

async function getAboutUs(periodoId) {
    return await getRequest(`${HOST}/api/v1/estrategia/aboutUs?periodoId=${periodoId}`);
}

async function getPESTEL(periodoId) {
    return await getRequest(`${HOST}/api/v1/estrategia/pestel?periodoId=${periodoId}`);
}

async function getFODA(periodoId) {
    return await getRequest(`${HOST}/api/v1/estrategia/foda?periodoId=${periodoId}`);
}

async function saveAboutUs(data, periodoId) {
    return await postRequest(`${HOST}/api/v1/estrategia/setAboutUs?periodoId=${periodoId}`, data);
}

async function savePESTEL(data, periodoId) {
    return await postRequest(`${HOST}/api/v1/estrategia/setPESTEL?periodoId=${periodoId}`, data);
}

async function saveFODA(data, periodoId) {
    return await postRequest(`${HOST}/api/v1/estrategia/setFODA?periodoId=${periodoId}`, data);
}

export { getAboutUs, saveAboutUs, getPESTEL, savePESTEL, getFODA, saveFODA };
